// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_openChats__JdkcZ {
  background-color: #f0f0f0;
  width: 100%;
  height: 100%;
}

.styles_InternalChats__m9nvG {
  height: 100%;
}

.styles_btnbg__ScNAn {
  background-color: white;
}

.styles_openHeader__v5AVA {
  cursor: pointer;
  border: none;
  background-color: #6892ff;
  border-radius: 50rem 0 0 0;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  width: 100%;
  height: 2rem;
}

.styles_openBody__Usl8V {
  height: 90%;
}

.styles_openHeader__v5AVA p {
  width: 100%;
  display: flex;
  justify-content: center;
}

.styles_openHeader__v5AVA img {
  height: 15px;
  display: flex;
  justify-content: left;
  position: absolute;
  margin-left: 1rem;
  margin-top: 0.2rem;
}

.styles_btncontent__\\+lsgm {
  align-items: center;
  width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/components/internalchats/styles.module.scss","webpack://./src/styles/colors.scss"],"names":[],"mappings":"AAGE;EACE,yBAAA;EACA,WAAA;EACA,YAAA;AAFJ;;AAKE;EACE,YAAA;AAFJ;;AAIE;EACE,uBAAA;AADJ;;AAGE;EACE,eAAA;EACA,YAAA;EACA,yBClBE;EDmBF,0BAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,YAAA;EACA,WAAA;EACA,YAAA;AAAJ;;AAGE;EACE,WAAA;AAAJ;;AAIA;EACI,WAAA;EACA,aAAA;EACJ,uBAAA;AADA;;AAGA;EACI,YAAA;EACA,aAAA;EACA,qBAAA;EACA,kBAAA;EACA,iBAAA;EACA,kBAAA;AAAJ;;AAGA;EACI,mBAAA;EACA,WAAA;AAAJ","sourcesContent":["@import '../../styles/colors.scss';\n\n\n  .openChats{\n    background-color: #f0f0f0;\n    width: 100%;\n    height: 100%;\n  }\n\n  .InternalChats{\n    height: 100%;\n  }\n  .btnbg{\n    background-color: white;\n  }\n  .openHeader{\n    cursor: pointer;\n    border: none;\n    background-color: $main;\n    border-radius: 50rem 0 0 0;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    color: white;\n    width: 100%;\n    height: 2rem;\n  }\n\n  .openBody{\n    height: 90%;\n  }\n\n  \n.openHeader p{\n    width: 100%;\n    display: flex;\njustify-content: center;\n}\n.openHeader img{\n    height: 15px;\n    display: flex;\n    justify-content: left;\n    position: absolute;\n    margin-left: 1rem;\n    margin-top: 0.2rem;\n}\n\n.btncontent{\n    align-items: center;\n    width: 100%;\n}\n\n  ","$main:#6892ff;\n$iacolor:#4CAF50;\n$primary:#ffffff;\n$secondary: #EAEAEA;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"openChats": `styles_openChats__JdkcZ`,
	"InternalChats": `styles_InternalChats__m9nvG`,
	"btnbg": `styles_btnbg__ScNAn`,
	"openHeader": `styles_openHeader__v5AVA`,
	"openBody": `styles_openBody__Usl8V`,
	"btncontent": `styles_btncontent__+lsgm`
};
export default ___CSS_LOADER_EXPORT___;
