// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./fonts/Gordita Regular.otf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("./fonts/Gordita Regular Italic.otf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("./fonts/Gordita Bold.otf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_3___ = new URL("./fonts/Gordita Black.otf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_4___ = new URL("./fonts/Gordita Bold Italic.otf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_5___ = new URL("./fonts/Gordita Medium.otf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_4___);
var ___CSS_LOADER_URL_REPLACEMENT_5___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_5___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `

@font-face {
    font-family: 'Gordita Regular';
    src: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Gordita Regular';
    src: url(${___CSS_LOADER_URL_REPLACEMENT_1___});
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'Gordita Regular';
    src: url(${___CSS_LOADER_URL_REPLACEMENT_2___});
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Gordita Black';
    src: url(${___CSS_LOADER_URL_REPLACEMENT_3___});
    font-weight: bolder;
    font-style: normal;
}


@font-face {
    font-family: 'Gordita Regular';
    src: url(${___CSS_LOADER_URL_REPLACEMENT_4___});
    font-weight: bold;
    font-style: italic;
}

@font-face{
    font-family: 'Gordita Medium';
    src: url(${___CSS_LOADER_URL_REPLACEMENT_5___});
    font-weight: normal;
    font-style: normal;
}`, "",{"version":3,"sources":["webpack://./src/fonts.scss"],"names":[],"mappings":";;AAEA;IACI,8BAA8B;IAC9B,4CAAwC;IACxC,mBAAmB;IACnB,kBAAkB;AACtB;;AAEA;IACI,8BAA8B;IAC9B,4CAA+C;IAC/C,mBAAmB;IACnB,kBAAkB;AACtB;;AAEA;IACI,8BAA8B;IAC9B,4CAAqC;IACrC,iBAAiB;IACjB,kBAAkB;AACtB;;AAEA;IACI,4BAA4B;IAC5B,4CAAsC;IACtC,mBAAmB;IACnB,kBAAkB;AACtB;;;AAGA;IACI,8BAA8B;IAC9B,4CAA4C;IAC5C,iBAAiB;IACjB,kBAAkB;AACtB;;AAEA;IACI,6BAA6B;IAC7B,4CAAuC;IACvC,mBAAmB;IACnB,kBAAkB;AACtB","sourcesContent":["\n\n@font-face {\n    font-family: 'Gordita Regular';\n    src: url('./fonts/Gordita\\ Regular.otf');\n    font-weight: normal;\n    font-style: normal;\n}\n\n@font-face {\n    font-family: 'Gordita Regular';\n    src: url('./fonts/Gordita\\ Regular Italic.otf');\n    font-weight: normal;\n    font-style: italic;\n}\n\n@font-face {\n    font-family: 'Gordita Regular';\n    src: url('./fonts/Gordita\\ Bold.otf');\n    font-weight: bold;\n    font-style: normal;\n}\n\n@font-face {\n    font-family: 'Gordita Black';\n    src: url('./fonts/Gordita\\ Black.otf');\n    font-weight: bolder;\n    font-style: normal;\n}\n\n\n@font-face {\n    font-family: 'Gordita Regular';\n    src: url('./fonts/Gordita\\ Bold Italic.otf');\n    font-weight: bold;\n    font-style: italic;\n}\n\n@font-face{\n    font-family: 'Gordita Medium';\n    src: url('./fonts/Gordita\\ Medium.otf');\n    font-weight: normal;\n    font-style: normal;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
