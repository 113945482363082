import React, { useState } from 'react';
import styles from './styles.module.scss';
import {Background} from '../background';
import {Navbar} from '../navbar';
import Cookies from 'js-cookie';

// O MainContainer envolve o conteúdo com o Navbar e o Background
const MainContainer = ({ children }) => {
  const [isVisible, setIsVisible] = useState(false);

  const handleClick = () => {
    const navbar = document.getElementById('navbar');
    navbar.classList.toggle(styles.openNavbar);
    setIsVisible(!isVisible);
  };

  return (
    <div className={styles.body}>
      <div className={styles.navbar} id="navbar">
        <Navbar 
          onClick={handleClick}
          title={isVisible ? "MaximizeAi" : ""}
          chats={isVisible ? "Chats" : ""}
          contatos={isVisible ? "Contatos" : ""}
          cotacoes={isVisible ? "Cotações" : ""}
          nome={isVisible ? Cookies.get("userName") : ""}
        />
      </div>
      <div className={styles.bg}>
        <Background onClick={handleClick} visibility={isVisible}>
          {children}
        </Background>
      </div>
      
    </div>
  );
};

export default MainContainer;