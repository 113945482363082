import React from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { ChatGroupItem } from '../chatGroupItem';
import Cookies from 'js-cookie';

const ItemTypes = {
  CHAT_GROUP: 'chatGroup',
};

export const DraggableChatGroupItem = ({ chatGroup, moveChatGroup, toggleCustomer, unknown }) => {
  const userId = Cookies.get("idName");

  const [{ isDragging }, dragRef] = useDrag({
    type: ItemTypes.CHAT_GROUP,
    item: { id: chatGroup.chat_id, position: chatGroup.chat_position },
    canDrag: () => chatGroup.chat_owner !== null,
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const [, dropRef] = useDrop({
    accept: ItemTypes.CHAT_GROUP,
    drop: (item) => {
      if (chatGroup.chat_owner === userId) {
        if (item.position !== chatGroup.chat_position) {
          moveChatGroup(item.position, chatGroup.chat_position);
          item.position = chatGroup.chat_position;
        }
      }
    },
  });

  return (
    <div ref={(node) => dragRef(dropRef(node))} style={{ opacity: isDragging ? 0.5 : 1 }}>
      <ChatGroupItem chatGroup={chatGroup} unknown={unknown} toggleCustomer={toggleCustomer} />
    </div>
  );
};
