// src/components/navbar/index.jsx
import styles from './styles.module.scss';
import logo from '../../assets/logo.png';
import chatsIcon from '../../assets/chats.png';
import ctts from '../../assets/ctts.png';
import quotes from '../../assets/quotes.png';
import settings from '../../assets/setting.png';
import profile from '../../assets/profile.png';
import { Link,useLocation } from 'react-router-dom';
import Cookies from "js-cookie";
import { authStore } from "../../stores/auth-store";

export const Navbar = ({onClick, title, chats, contatos, cotacoes, nome }) => {
    const location = useLocation();
    const sair = ()=>{
        authStore.setAccessToken('');
        authStore.setRefreshToken('');
        Cookies.remove("accesstoken");
        Cookies.remove("refreshtoken");
        window.location.reload();
    }
    return (
        <div className={styles.navbar} id="navbar">
            <div className={styles.navheader} onClick={onClick}>
                <img src={logo} alt="" className="logo" />
                <div className={`${styles.title} ${title ? styles.visible : ""}`}>{title}</div>
            </div>
            <div className={styles.espaco}></div>
            <div className={styles.contents}>
            <Link to="/chats">
                <div className={`${styles.line} ${title ? styles.lines : ""} ${location.pathname === "/chats" ? styles.active : ""}`}>
                        <img src={chatsIcon} alt="" />
                        <p>{chats}</p>
                </div>
            </Link>
            <Link to="/contacts">
                <div className={`${styles.line} ${title ? styles.lines : ""} ${location.pathname === "/contacts" ? styles.active : ""}`}>
                        <img src={ctts} alt="" />
                        <p>{contatos}</p>
                </div>
            </Link>
            <Link to="/quotes">
                <div className={`${styles.line} ${title ? styles.lines : ""} ${location.pathname === "/quotes" ? styles.active : ""}`}>
                        <img src={quotes} alt="" />
                        <p>{cotacoes}</p>
                </div>
            </Link>
            </div>
            <div className={styles.footerspace} onClick={onClick}>
                </div>
            <div className={`${styles.navfooter} ${title ? styles.opennavfooter : ""}`} onClick={onClick}>
                
                    
                <div className={`${styles.settings} ${title ? styles.opensettings : ""}`}>
                    <img src={settings} alt="" />
                </div>
                <div className={`${styles.profile} ${title ? styles.openprofile : ""}`}>
                    <img src={profile} alt="" />

                    <div className={`${styles.nome} ${nome ? styles.logout:""}`}>
                    <div>{nome}</div>
                    <div className={styles.sair} onMouseUp={sair}>Sair</div>
                    </div>
                </div>
                
            </div>
        </div>
    );
};