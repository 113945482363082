import React from 'react'
import Button from '@mui/material/Button'
import './styles.modules.scss'

export const CustomExcludeButton =({ text, type, onClick }) => {
    return (
        <Button variant="contained" className="excludeButton" color="error" type={type} onClick={onClick} fullWidth>
            {text}
        </Button>
    );
}
