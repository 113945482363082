import useAxios from "./api";

const useCustomerService = (header) => {
    const {api} = useAxios();

    return {
        customers: {
            get: async(pk=null) => {
                
                return pk ? await api.get(`/api/customers/${pk}`, {headers : header}) : await api.get('/api/customers', {headers : header});
            },
            post: async(data) => {
                return await api.post('/api/customers', data, {headers : header})
            },
            put: async(pk, data) => {
                return await api.put(`/api/customers/${pk}`, data, {headers : header})
            },
            delete: async(pk) => {
                return await api.delete(`/api/customers/${pk}`, {headers : header})
            }
        },
        customerPhone: async (phone) =>{
            try{
                const data={
                        "through_cellphone": 'true',
                        "cell_phone_number": phone
                }
                return await api.post(`/api/customers`, data, {headers : header});
            }
            catch {
                return null;
            }
        }
    };
};

export default useCustomerService;