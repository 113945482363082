import useAxios from "./api";
import useFileAxios from "./flieService";
import { toast } from 'react-toastify';

export const useChatService = () => {
    const { api } = useAxios();

    const {fileApi} = useFileAxios();
    return {
        getAllMessages: async (phone) => {
            try {
                const response = await api.get(`/api/chat/external/messages?contact=${phone}`);
                if (response.data === "[]") {
                    return [];
                }
                return response.data;
            } catch (error) {
                return [];
            }
        },

            
        postFile: async (blobUrl, type) => {
            try {
                // Obtenha o blob a partir da URL
                const response = await fetch(blobUrl);
                const blob = await response.blob();
        
                let mimeType = blob.type;
                let filename;
        
                if (type === "audio") {
                    // Defina o tipo MIME específico para áudio com codec Opus
                    mimeType = 'audio/mp3; codecs=opus';
                    filename = 'recording.mp3'; // Nome fixo para áudio OGG
                } else {
                    // Para outros tipos, mantenha o tipo MIME original
                    const extension = mimeType.split('/')[1];
                    filename = `recording.${extension}`;
                }
        
                // Crie uma nova instância de FormData
                const formData = new FormData();
                
                formData.append('file', blob, filename); // Usa o nome do arquivo com a extensão dinâmica
        
        
                // Envie a requisição para o servidor
                console.log(formData);
                const result = await fileApi.post("/upload", formData);
        
                return result.data; // Assumindo que o fileApi retorna a resposta JSON diretamente
            } catch (error) {
                console.error(error);
            }
        },

        postMessage: async (data, headers) => {

            try {
                return await api.post("/api/whatsapp/send", data, { headers: headers });
            } catch (error) {
                console.log('Erro ao enviar mensagem:', error);
                throw error;
            }
        },

        postNote: async () => {
            return await api.get("/api/notas");
        },

        activeChatBot: {
            get: async (params) => { return await api.get(`/api/chat/external/autoreply?${params}`) },
            post: async (data) => { return await api.post(`/api/chat/external/autoreply?target_phone=${data.target_phone}`, data) },
            put: async (data) => {
                try {
                    const response = await api.put("/api/chat/external/autoreply", data);
                    return response.data;
                } catch {
                    toast.error("Erro ao ativar/desativar chatbot")
                }
            }
        },

        messageSuggestions: async (data) => {
            try {
                const response = await api.post("/api/chat/external/message_suggestion", data);
                return response.data;
            } catch {
                return [];
            }
        }
    }
};
