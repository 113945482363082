import React from 'react'
import Button from '@mui/material/Button'
import './styles.modules.scss'

function CustomButton({ text, type, onClick }) {
    return (
        <Button className="confirmButton" type={type} onClick={onClick} fullWidth>
            {text}
        </Button>
    );
}

export default CustomButton