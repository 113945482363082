import { Close } from '@mui/icons-material';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import styles from './styles.module.scss';
import { CustomExcludeButton } from '../excludeButton';
import useUserServices from '../../services/user';
import Cookies from 'js-cookie';
import { useState, useEffect } from 'react';

export const UserExclude = ({ toggleExclude, userId }) => {
    const { deleteUser, getUser } = useUserServices();
    const [userData, setUserData] = useState([]);
    useEffect(() => {
        const handleViewUser = async () => {
          const response = await getUser(userId, {"Authorization":`Bearer ${Cookies.get("accesstoken")}`});
          setUserData(response.data);
        };
        console.log(userData.name)
        handleViewUser();
      }, []);

    const handleDelete = async () => {
        try {
            await deleteUser(userId, {"Authorization":`Bearer ${Cookies.get("accesstoken")}`});
            toast.success('Usuário excluído com sucesso!');
            setTimeout(() => {
                window.location.reload();
            }, 4500);
        } catch (error) {
            console.log(error)
            toast.error('Erro ao excluir usuário.');
        }
    };

    return (
        <div className={styles.registerBackground}>
            <ToastContainer />
            <div className={styles.registerContainer}>
                <div className={styles.registerHeader}>
                    <h2 className={styles.titleregister}>Excluir Usuário</h2>
                    <div className={styles.close}>
                        <Close onClick={toggleExclude} />
                    </div>
                </div>
                <div className={styles.deleteBody}>
                    <div>
                        <div>Deseja excluir o usuário <b>{userData.name}</b>?</div> 
                        <div className={styles.confirm}>Confirmar exclusão</div>
                    </div>
                </div>
                <div className={styles.deleteFooter}>
                    <CustomExcludeButton text="Excluir" onClick={handleDelete} />
                </div>
            </div>
        </div>
    );
};
