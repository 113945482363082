import React, { useState, useRef, useEffect } from 'react';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { Close } from '@mui/icons-material';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import styles from './styles.module.scss';
import CustomInput from '../input';
import CustomButton from '../button';
import { CustomSelect } from '../select';
import getCountryCodes from '../countryCodes';
import useUserServices from '../../services/user';
import Cookies from 'js-cookie';

const countryCodes = getCountryCodes();

export const UserEdit = ({ toggleEdit, userId }) => {
    const [imageUrl, setImageUrl] = useState(null);
    const fileInputRef = useRef(null);
    const level = [{ label: "Admin", value: 0 }, { label: "User", value: "string" }];
    const [showPassword, setShowPassword] = useState(false);
    const [userData, setUserData] = useState({});
    const { updateUser, getUser } = useUserServices();

    useEffect(() => {
        const handleViewUser = async () => {
            const response = await getUser(userId, { "Authorization": `Bearer ${Cookies.get("accesstoken")}` });
            setUserData(response.data);
        };
        handleViewUser();
    }, [getUser, userId]);

    const validationSchema = Yup.object({
        name: Yup.string().required('Campo nome é obrigatório'),
        email: Yup.string().email('Email inválido').required('Campo email é obrigatório'),
        role: Yup.string().required('Campo perfil de acesso é obrigatório'),
    });

    const formik = useFormik({
        initialValues: {
            chat_status: true,
            name: userData.name || '',
            email: userData.email || '',
            role: userData.role !== undefined ? String(userData.role) : '',
            countryCode: userData.countryCode || '',
            password: '',
            passwordConfirm: '',
        },
        enableReinitialize: true,
        validationSchema,
        validate: (values) => {
            const errors = {};
            if (values.password && !values.passwordConfirm) {
                errors.passwordConfirm = 'Confirmação de senha é obrigatória';
            } else if (values.password && values.password !== values.passwordConfirm) {
                errors.passwordConfirm = 'As senhas não conferem';
            }
            return errors;
        },
        onSubmit: (values) => {
            const { passwordConfirm, showPassword, countryCode, password, ...submitValues } = values;
        
            // Inclui a senha no objeto de submissão apenas se ela estiver preenchida.
            if (password) {
                submitValues.password = password;
            }
        
            console.log("Valores submetidos:", submitValues);
            updateUser(userId, { "Authorization": `Bearer ${Cookies.get("accesstoken")}` }, submitValues)
                .then(() => {
                    toast.success("Usuário atualizado com sucesso");
                    setTimeout(() => {
                        window.location.reload();
                    }, 4500);
                })
                .catch((error) => {
                    console.error("Erro ao editar usuário:", error);
                    toast.error("Erro ao editar usuário!");
                });
        },
        
        
        validateOnChange: false,
        validateOnBlur: false,
    });

    useEffect(() => {
        if (formik.errors) {
            Object.keys(formik.errors).forEach((key) => {
                toast.error(formik.errors[key]);
            });
        }
    }, [formik.errors]);

    const handleImageChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            setImageUrl(URL.createObjectURL(file));
        }
    };

    const excluiImg = () => {
        setImageUrl(null);
        if (fileInputRef.current) {
            fileInputRef.current.value = "";
        }
    };

    const handleShowPassword = () => {
        setShowPassword(true);
        formik.setFieldValue('showPassword', true);
    };

    return (
        <div className={styles.registerBackground}>
            <ToastContainer />
            <div className={styles.registerContainer}>
                <div className={styles.registerHeader}>
                    <h2 className={styles.titleregister}>Editar usuário</h2>
                    <div className={styles.close}>
                        <Close onClick={toggleEdit} />
                    </div>
                </div>
                <form onSubmit={formik.handleSubmit} method="post">
                    <div className={styles.form}>
                        <div className={styles.rightside}>
                            <div className={styles.formInput}>
                                <CustomInput
                                    type="text"
                                    id="name"
                                    label="Digite aqui o seu nome"
                                    name="name"
                                    autoComplete="name"
                                    value={formik.values.name}
                                    onChange={formik.handleChange}
                                    error={formik.touched.name && Boolean(formik.errors.name)}
                                    size={"small"}
                                />
                            </div>
                            <div className={styles.formInput}>
                                <div className={styles.phoneInputs}>
                                    <div className={styles.customSelect}>
                                        <CustomSelect
                                            id="countryCode"
                                            label="Localização"
                                            name="countryCode"
                                            value={formik.values.countryCode}
                                            onChange={(event) => formik.setFieldValue('countryCode', event.target.value)}
                                            options={countryCodes}
                                            error={formik.touched.countryCode && Boolean(formik.errors.countryCode)}
                                        />
                                    </div>
                                    <CustomInput
                                        type="text"
                                        id="phone"
                                        label="Digite aqui o seu telefone"
                                        name="phone"
                                        autoComplete="phone"
                                        size={"small"}
                                    />
                                </div>
                            </div>
                            <div className={styles.formInput}>
                                <CustomInput
                                    type="text"
                                    id="email"
                                    label="Digite aqui o seu email"
                                    name="email"
                                    autoComplete="email"
                                    value={formik.values.email}
                                    onChange={formik.handleChange}
                                    error={formik.touched.email && Boolean(formik.errors.email)}
                                    size={"small"}
                                />
                            </div>
                            <div className={styles.formInput}>
                                <CustomSelect
                                    id="role"
                                    label="Selecione o perfil de acesso"
                                    name="role"
                                    value={formik.values.role}
                                    onChange={(event) => formik.setFieldValue('role', event.target.value)}
                                    options={level}
                                    error={formik.touched.role && Boolean(formik.errors.role)}
                                />
                            </div>
                            {!showPassword &&
                                <div onClick={handleShowPassword} className={styles.showPassword}>Alterar senha</div>}
                            {showPassword && <div className={styles.formInput}>
                                <CustomInput
                                    type="password"
                                    id="password"
                                    label="Senha"
                                    name="password"
                                    value={formik.values.password}
                                    onChange={formik.handleChange}
                                    error={formik.touched.password && Boolean(formik.errors.password)}
                                    size={"small"}
                                />
                            </div>}
                        </div>
                        <div className={styles.leftside}>
                            <div className={styles.imagecontainer}>
                                <div className={styles.imageinput}>
                                    {imageUrl ? (
                                        <>
                                            <img src={imageUrl} alt="User" className={styles.previewImage} />
                                            <div onClick={excluiImg} className={styles.excluiImg}><Close /></div>
                                        </>
                                    ) : (
                                        <div className={styles.placeholder}></div>
                                    )}
                                    <input
                                        type="file"
                                        accept="image/*"
                                        onChange={handleImageChange}
                                        className={styles.fileInput}
                                        ref={fileInputRef}
                                    />
                                </div>
                            </div>
                            {showPassword &&
                                <div className={styles.formInput}>
                                    <CustomInput
                                        type="password"
                                        id="passwordConfirm"
                                        label="Confirme a senha"
                                        name="passwordConfirm"
                                        value={formik.values.passwordConfirm}
                                        onChange={formik.handleChange}
                                        error={formik.touched.passwordConfirm && Boolean(formik.errors.passwordConfirm)}
                                        size={"small"}
                                    />
                                </div>
                            }
                        </div>
                    </div>
                    <div className={styles.submitbtn}>
                        <CustomButton text="Salvar alterações" type="submit" className={styles.extraPadding} />
                    </div>
                </form>
            </div>
        </div>
    );
};
