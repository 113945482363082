// abrir.js
import menu from '../../assets/menu.png'; // Importe a imagem do menu
import close from '../../assets/close.png'; // Importe a imagem alternativa do menu
import styles from './styles.module.scss';
import { useEffect } from 'react';

export const OpenBar = ({ onClick, visibility }) => {
    const handleOnClick = () => {
        onClick(); // Chama a função onClick passada como prop
    };
    useEffect(()=>{

        inverterIcone(); // Chama a função inverterIcone
    },[visibility])


    const inverterIcone = () => {
        const icone = document.getElementById('icone-menu'); // Obtém o elemento da imagem pelo ID
        const iconeSrc = icone.src.split('/').pop();

    if (visibility) {
        
        icone.src = close;
    } else {
        icone.src = menu;
    }
    };

    return (
        <img src={menu} id="icone-menu" className={styles.menuIcon} onClick={handleOnClick} alt="Menu" />
    );
};