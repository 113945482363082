import React, { useState, useRef, useEffect } from 'react';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { Close, Refresh } from '@mui/icons-material';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import styles from './styles.module.scss';
import CustomInput from '../input';
import CustomButton from '../button';
import { CustomSelect } from '../select';
import getCountryCodes from '../countryCodes';
import useUserServices from '../../services/user';

export const UserRegister = ({isOpen, setUserRegisterOpen}, { toggleRegister }) => {
    const [imageUrl, setImageUrl] = useState(null);
    const fileInputRef = useRef(null);
    const countryCodes = getCountryCodes();
    const level = [{ label: "Admin", value: 0 }, { label: "User", value: 1 }];
    const { userRegister } = useUserServices();

    const validationSchema = Yup.object({
        name: Yup.string().required('Campo nome é obrigatório'),
        email: Yup.string().email('Email inválido').required('Campo email é obrigatório'),
        password: Yup.string().required('Campo senha é obrigatório'),
        passwordConfirm: Yup.string()
            .required('Confirmação de senha é obrigatória')
            .oneOf([Yup.ref('password'), null], 'As senhas não conferem'),
        role: Yup.string().required('Campo perfil de acesso é obrigatório'),
    });

    const formik = useFormik({
        initialValues: {
            chat_status: true,
            name: '',
            email: '',
            role: '',
            password: '',
            passwordConfirm: '',
        },
        validationSchema,
        onSubmit: (values) => {
            const { passwordConfirm, ...submitValues } = values; // Remover passwordConfirm antes de enviar
            userRegister(submitValues)
                .then((resp) => {
                    toast.success("Usuário cadastrado com sucesso");
                    setTimeout(() => {
                        window.location.reload();
                    }, 1500);
                })
                .catch(() => {
                    toast.error("Este email já está em uso!");
                });
        },
        validateOnChange: false,
        validateOnBlur: false,
    });

    useEffect(() => {
        if (formik.errors) {
            Object.keys(formik.errors).forEach((key) => {
                toast.error(formik.errors[key]);
            });
        }
    }, [formik.errors]);

    const handleImageChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            setImageUrl(URL.createObjectURL(file));
        }
    };

    const excluiImg = () => {
        setImageUrl(null);
        if (fileInputRef.current) {
            fileInputRef.current.value = "";
        }
    };    

    if (isOpen) {
        return <div className={styles.registerBackground}>
        <ToastContainer />
        <div className={styles.registerContainer}>
            <div className={styles.registerHeader}>
                <h2 className={styles.titleregister}>Novo Usuário</h2>
                <div className={styles.close}>
                    <Close onClick={setUserRegisterOpen}/>
                </div>
            </div>
            <form onSubmit={formik.handleSubmit} method="post">
                <div className={styles.form}>
                    <div className={styles.rightside}>
                        <div className={styles.formInput}>
                            <CustomInput
                                type="text"
                                id="name"
                                label="Digite aqui o seu nome"
                                name="name"
                                autoComplete="name"
                                value={formik.values.name}
                                onChange={formik.handleChange}
                                error={formik.touched.name && Boolean(formik.errors.name)}
                                size={"small"}
                            />
                        </div>
                        <div className={styles.formInput}>
                            <div className={styles.phoneInputs}>
                                <div className={styles.customSelect}>
                                    <CustomSelect
                                        id="countryCode"
                                        label="Localização"
                                        name="countryCode"
                                        value={formik.values.countryCode}
                                        options={countryCodes}
                                        error={formik.touched.countryCode && Boolean(formik.errors.countryCode)}
                                    />
                                </div>
                                <CustomInput
                                    type="text"
                                    id="phone"
                                    label="Digite aqui o seu telefone"
                                    name="phone"
                                    autoComplete="phone"
                                    size={"small"}
                                />
                            </div>
                        </div>
                        <div className={styles.formInput}>
                            <CustomInput
                                type="text"
                                id="email"
                                label="Digite aqui o seu email"
                                name="email"
                                autoComplete="email"
                                value={formik.values.email}
                                onChange={formik.handleChange}
                                error={formik.touched.email && Boolean(formik.errors.email)}
                                size={"small"}
                            />
                        </div>
                        <div className={styles.formInput}>
                            <CustomSelect
                                id="role"
                                label="Selecione o perfil de acesso"
                                name="role"
                                value={formik.values.role}
                                onChange={(event) => formik.setFieldValue('role', event.target.value)}
                                options={level}
                                error={formik.touched.role && Boolean(formik.errors.role)}
                            />
                        </div>
                        <div className={styles.formInput}>
                            <CustomInput
                                type="password"
                                id="senha"
                                label="Digite aqui a senha"
                                name="password"
                                value={formik.values.password}
                                onChange={formik.handleChange}
                                error={formik.touched.password && Boolean(formik.errors.password)}
                                size={"small"}
                            />
                        </div>
                    </div>
                    <div className={styles.leftside}>
                        <div className={styles.imagecontainer}>
                            <div className={styles.imageinput}>
                                {imageUrl ? (
                                    <>
                                        <img src={imageUrl} alt="User" className={styles.previewImage} />
                                        <div onClick={excluiImg} className={styles.excluiImg}><Close /></div>
                                    </>
                                ) : (
                                    <div className={styles.placeholder}></div>
                                )}
                                <input
                                    type="file"
                                    accept="image/*"
                                    onChange={handleImageChange}
                                    className={styles.fileInput}
                                    ref={fileInputRef}
                                />
                            </div>
                        </div>
                        <div className={styles.formInput}>
                            <CustomInput
                                type="password"
                                id="senhaconfirm"
                                label="Confirme a senha"
                                name="passwordConfirm"
                                value={formik.values.passwordConfirm}
                                onChange={formik.handleChange}
                                error={formik.touched.passwordConfirm && Boolean(formik.errors.passwordConfirm)}
                                size={"small"}
                            />
                        </div>
                    </div>
                </div>
                <div className={styles.submitbtn}>
                    <CustomButton text="Cadastrar usuário" type="submit" className={styles.extraPadding} />
                </div>
            </form>
        </div>
    </div>
    }

    return null
   
};
