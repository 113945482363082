import React, { useRef, useState, useEffect } from 'react';
import { IconButton, TextField, InputAdornment, Box, Typography } from '@mui/material';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import EmojiEmotionsIcon from '@mui/icons-material/EmojiEmotions';
import SendIcon from '@mui/icons-material/Send';
import Mic from '@mui/icons-material/Mic';
import EmojiPicker from 'emoji-picker-react';
import AudioRecorder from '../audioRecorder';
import styles from './ChatInput.module.scss';

const ChatInput = ({ message, setMessage, chatBot, onSendMessage }) => {
    const [file, setFile] = useState(null);
    const [filePreview, setFilePreview] = useState(null);
    const [showEmojiPicker, setShowEmojiPicker] = useState(false);
    const [showAudioRecorder, setShowAudioRecorder] = useState(false);
    const inputRef = useRef(null);
    const fileInputRef = useRef(null);

    useEffect(() => {
        if (file && file.type.startsWith('image/')) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setFilePreview(reader.result);
            };
            reader.readAsDataURL(file);
        } else {
            setFilePreview(null);
        }
    }, [file]);

    const handleInputChange = (event) => {
        setMessage(event.target.value);
    };

    const handleFileChange = (event) => {
        const selectedFile = event.target.files[0];
        if (selectedFile) {
            setFile(selectedFile);
        }
    };

    const handleEmojiClick = (emojiObject) => {
        setMessage((prevMessage) => prevMessage + emojiObject.emoji);
    };

    const handleFileRemove = () => {
        setFile(null);
        setFilePreview(null);
        fileInputRef.current.value = ''; // Reset the file input value
    };

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            handleSendClick();
        }
    };

    const handleAudioClose = () => {
        setShowAudioRecorder(false);
    };

    const handleSend = (message, audioBlob, image, file) => {
        if (message) {
            onSendMessage({ type: 'text', content: message });
        }
        if (audioBlob) {
            onSendMessage({ type: 'audio', content: audioBlob });
        }
        if (image) {
            onSendMessage({ type: 'image', content: image, text: message });
        }
        if (file) {
            onSendMessage({ type: 'file', content: file, text: message });
        }
        setMessage('');
        setFile(null);
        setFilePreview(null);
    };

    const handleSendClick = () => {
        if(file){
        const fileURL = URL.createObjectURL(file);
        handleSend(message, null, null, fileURL);
        } else{
        handleSend(message, null, null, null);
        }
    };

    const handleImageSend = () => {
            const imageUrl = URL.createObjectURL(file);
            handleSend(message, null, imageUrl, null);
    };

    const handleAudioSend = (audioBlob) => {
        handleSend(null, audioBlob, null, null);
        setShowAudioRecorder(false);
    };

    return (
        <Box className={styles.chatInputContainer}>
            {showAudioRecorder ? (
                <AudioRecorder onClose={handleAudioClose} onSend={handleAudioSend} />
            ) : (
                <>
                    <TextField
                        inputRef={inputRef}
                        multiline
                        rows={1}
                        variant="outlined"
                        value={message}
                        onChange={handleInputChange}
                        onKeyPress={handleKeyPress}
                        placeholder={chatBot ? 'ChatBot está ativo' : "Digite uma mensagem"}
                        className={styles.inputField}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton component="label">
                                        <AttachFileIcon />
                                        <input type="file" hidden onChange={handleFileChange} ref={fileInputRef} />
                                    </IconButton>
                                    <IconButton onClick={() => setShowEmojiPicker((prev) => !prev)}>
                                        <EmojiEmotionsIcon />
                                    </IconButton>
                                    {message || file ? (
                                        <IconButton onClick={handleSendClick}>
                                            <SendIcon />
                                        </IconButton>
                                    ) : (
                                        <IconButton onClick={() => setShowAudioRecorder(true)}>
                                            <Mic />
                                        </IconButton>
                                    )}
                                </InputAdornment>
                            ),
                        }}
                    />
                    {file && (
                        <Box className={styles.fileInfo}>
                            <div className={styles.filesdiv}>
                                <Typography variant="body2">{file.name}</Typography>
                                <Typography variant="body2">{(file.size / 1024).toFixed(2)} KB</Typography>
                            </div>
                            <button onClick={handleFileRemove}>Excluir</button>
                        </Box>
                    )}
                    {filePreview && (
                        <div className={styles.imageView}>
                            <div className={styles.imageBody}>
                                <img src={filePreview} className={styles.selectedImage} alt="preview" />
                                <TextField
                                    inputRef={inputRef}
                                    multiline
                                    rows={1}
                                    variant="outlined"
                                    value={message}
                                    onChange={handleInputChange}
                                    onKeyPress={handleKeyPress}
                                    placeholder={chatBot ? 'ChatBot está ativo' : "Digite uma mensagem"}
                                    className={styles.inputField}
                                />
                                <div className={styles.sendExcludeImage}>
                                    <div onClick={handleFileRemove}>Excluir</div>
                                    <div onClick={handleImageSend}>Enviar</div>
                                </div>
                            </div>
                        </div>
                    )}
                    {showEmojiPicker && (
                        <div className={styles.emojiPicker}>
                            <EmojiPicker onEmojiClick={handleEmojiClick} />
                            <button className={styles.closeEmojiPickerButton} onClick={() => setShowEmojiPicker(false)}>Fechar</button>
                        </div>
                    )}
                </>
            )}
        </Box>
    );
};

export default ChatInput;
