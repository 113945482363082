import useAxios from "./api";

const useUserServices = () =>{
    const {api} = useAxios();

    return{
        userRegister: async(data) => {
            return await api.post('/api/auth/register', data);
        },
        userGetAll: async(header)=>{
            return await api.get('/api/auth/getall',{headers:header});
        },
        getUser: async(id, header)=>{
            return await api.get(`/api/auth/get/${id}`, {headers:header});
        },
        updateUser: async(id, header, data)=>{
            return await api.put(`/api/auth/update/${id}`, data, {headers:header});
        },
        deleteUser: async(id, header)=>{
            return await api.delete(`/api/auth/delete/${id}`, {headers:header});
        },
    }
};
export default useUserServices;