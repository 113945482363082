import { useEffect, useState } from 'react';
import styles from './styles.module.scss';
import { useChatGroupService } from '../../services/chatgroup';
import Cookies from 'js-cookie';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Close } from '@mui/icons-material';
import useCustomerService from '../../services/customers';
import {useUnknownServices} from '../../services/unknown';
import { useChatService } from '../../services/chat';


export const CreateCustomerInGroup = ({number, groupVisibility, send, createNewGroup}) => {
    const {tempCostumerDeleteByPhone} = useUnknownServices({ "Authorization": `Bearer ${Cookies.get("accesstoken")}` });
    const { postMessage} = useChatService();
    const [allGroups, setAllGroups] = useState([]);
    const [selectChatGroup, setSelectedChatGroup] = useState();
    const userId = Cookies.get("idName");
    const { customers } = useCustomerService({"Authorization": `Bearer ${Cookies.get("accesstoken")}`});

    const { getAllGroups, putGroup } = useChatGroupService();

    useEffect(() => {
        const fetchGroups = async () => {
            const response = await getAllGroups();
            if (response && response.data) {
                setAllGroups(response.data);
                console.log("Fetched groups:", response.data);
            }
        };
        fetchGroups();
    }, []);

    const includeInGroup = (group) => {
        setSelectedChatGroup(group);
        console.log(group);
    }

    const CloseGroup = () =>{
        groupVisibility(false);
    }

    const addInGroup = async (number) => {
        if (selectChatGroup && number) {
            // Adicionar o número como um novo cliente
            const customer = {
                id: null,
                fullName: 'Contato',
                birthDate: '2024-08-01',
                cellPhone: number,
                customer_owner: Cookies.get("idName")
            };
            customers.post(customer).then((response) => {
                console.log("Cliente adicionado:", response.data);
            }).catch((error) => {
                console.error("Erro ao adicionar cliente:", error);
            });
            tempCostumerDeleteByPhone(number);
            const updatedNumbers = `${selectChatGroup.chat_numbers},${number}`;
            const groupValues = {
                chat_owner: userId,
                chat_name: selectChatGroup.chat_name,
                chat_numbers: updatedNumbers,
                chat_position: selectChatGroup.chat_position
            };
            const response = await putGroup(selectChatGroup.chat_id, groupValues);

            if (response) {
                const groupToRemoveFrom = allGroups.find(group => group.chat_id === 1);
                if (groupToRemoveFrom) {
                    let updatedNumbersForGroup1 = groupToRemoveFrom.chat_numbers
                        .split(',')
                        .filter(num => num !== number) // Remove o número específico
                        .join(',');
                
                    // Remover possíveis vírgulas extras no início ou no fim
                    updatedNumbersForGroup1 = updatedNumbersForGroup1
                        .replace(/,{2,}/g, ',')  // Remove múltiplas vírgulas consecutivas
                        .replace(/^,|,$/g, '');  // Remove vírgula no início ou fim
                
                    const updatedGroup1Values = {
                        ...groupToRemoveFrom,
                        chat_numbers: updatedNumbersForGroup1
                    };
                
                    await putGroup(groupToRemoveFrom.chat_id, updatedGroup1Values);
                    console.log("Número removido do grupo 1");
                }
                

                await postMessage(send, { "Authorization": `Bearer ${Cookies.get("accesstoken")}` });
                alert("Contato salvo com sucesso!");
                window.location.reload();
            } else {
                console.error("Erro ao adicionar número ao grupo");
            }
        } else {
            alert("Selecione um grupo");
        }
    }

    return (
        <div className={styles.customerBackground}>
            <div className={styles.customerContainer}>
                <div className={styles.customerHeader}>
                    <h2 className={styles.titlecustomer}>Adicionar contato a um grupo</h2>
                    <div className={styles.close}>
                        <Close onClick={CloseGroup}/>
                    </div>
                </div>
                <h3>Selecione um grupo para adicionar o contato</h3>

                <div className={styles.selectChatGroup}>
                    <div className={styles.create} onClick={createNewGroup}>Criar novo grupo</div>
                    {allGroups.map(chatGroup => (
                        (chatGroup.chat_owner === userId) && (
                            <div className={styles.line} onClick={() => includeInGroup(chatGroup)} data-selected={selectChatGroup && selectChatGroup.chat_id === chatGroup.chat_id}>
                                {chatGroup.chat_name}
                            </div>
                        )
                    ))}
                </div>

                <div className={styles.customerFooter}>
                    <div className={styles.enter} onClick={() => addInGroup(number)}>
                        <ArrowForwardIcon />
                    </div>
                </div>
            </div>
        </div>
    )
}
