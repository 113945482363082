import React, { useEffect, useState } from 'react';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import styles from './styles.module.scss';
import { Description } from '../docDescription';

const getFileNameAndExtension = (filePath) => {
    const fileName = filePath.split('/').pop();
    const [name, ...extParts] = fileName.split('.');
    const extension = extParts.length > 0 ? extParts.pop() : '';
    return { name, extension };
};

const getFileSize = async (filePath) => {
    try {
        const response = await fetch(filePath);
        if (!response.ok) throw new Error('Network response was not ok');
        const blob = await response.blob();
        return (blob.size / 1024).toFixed(2); // Size in KB
    } catch (error) {
        console.error('Error fetching file size:', error);
        return 'Unknown';
    }
};

export const FileMessageView = ({ file, sender, text }) => {
    const [fileSize, setFileSize] = useState(null);
    const [fileInfo, setFileInfo] = useState({ name: '', extension: '' });

    useEffect(() => {
        const fetchFileDetails = async () => {
            const { name, extension } = getFileNameAndExtension(file);
            const size =""; //await getFileSize(file);
            setFileInfo({ name, extension });
            setFileSize(size);
        };

        fetchFileDetails();
    }, [file]);

    return (
        <div className={styles.FileMessageView} data-sender={sender}>
            <div className={styles.FileContainer}>
            <div className={styles.FileMessageLink}>
                <InsertDriveFileIcon className={styles.fileIcon} />
                <a href={file} download={`${text}`}>
                    <div className={styles.FileInfo}>
                        <div>{text}</div>
                    </div>
                </a>
            </div>
            {text &&
                <Description text={text} />
            }
            </div>
        </div>
    );
};