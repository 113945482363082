import React, { useState } from 'react';
import './RightSidebar.css';

function RightSidebar() {
    const [isExpanded, setIsExpanded] = useState(false);

    const toggleRightSidebar = () => {
        setIsExpanded(!isExpanded);
    };

    return (
        <div className={`right-sidebar ${isExpanded ? 'expanded' : 'minimized'}`}>
            <div className="sidebar-content">
                <h2>Online</h2>
                <ul>
                    <li>Fulano da Silva</li>
                    <li>Beltrana Lima</li>
                    <li>Equipe 7 Ases</li>
                    <li>MaximizeAi</li>
                </ul>
            </div>
            <button className="toggle-right-sidebar" onClick={toggleRightSidebar}>
                {isExpanded ? '⇩' : '⇧'}
            </button>
        </div>
    );
}

export default RightSidebar;
