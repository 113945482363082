// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.audio-player audio::-webkit-media-controls-panel {
  background-color: #ffffff;
}

.audio-player {
  background-color: #fff;
  border: none;
  border-radius: 5px;
}

.audio-player[data-sender=user] {
  background-color: #6892ff;
}

.audio-player[data-sender=user] audio::-webkit-media-controls-panel {
  background-color: #6892ff;
  border-radius: 0px !important;
}

.audio-player[data-sender=user] audio::-webkit-media-controls-play-button,
.audio-player[data-sender=user] audio::-webkit-media-controls-pause-button,
.audio-player[data-sender=user] audio::-webkit-media-controls-timeline,
.audio-player[data-sender=user] audio::-webkit-media-controls-mute-button,
.audio-player[data-sender=user] audio::-webkit-media-controls-unmute-button,
.audio-player[data-sender=user] audio::-webkit-media-controls-settings-button,
.audio-player[data-sender=user] audio::-webkit-media-controls-overflow-menu-button,
.audio-player[data-sender=user] audio::-webkit-media-controls-volume-slider {
  filter: invert(1);
  box-shadow: none !important;
  filter: drop-shadow(none);
}

.audio-player[data-sender=user] audio::-webkit-media-controls-current-time-display,
.audio-player[data-sender=user] audio::-webkit-media-controls-time-remaining-display {
  all: unset;
  color: #fff;
  box-shadow: 0;
  font-family: "Gordita";
}`, "",{"version":3,"sources":["webpack://./src/components/audioPlayer/AudioPlayer.scss","webpack://./src/styles/colors.scss"],"names":[],"mappings":"AACI;EAEA,yBCDK;ADAT;;AAIA;EACI,sBAAA;EACA,YAAA;EACA,kBAAA;AADJ;;AAIA;EACI,yBCbE;ADYN;;AAIA;EACI,yBCjBE;EDkBF,6BAAA;AADJ;;AAIA;;;;;;;;EAQI,iBAAA;EACA,2BAAA;EACA,yBAAA;AADJ;;AAIA;;EAEI,UAAA;EACA,WAAA;EACA,aAAA;EACA,sBAAA;AADJ","sourcesContent":["@import '../../styles/colors.scss';\n    .audio-player audio::-webkit-media-controls-panel {\n    \n    background-color: $primary;\n  }\n\n.audio-player {\n    background-color: #fff;\n    border: none;\n    border-radius: 5px;\n}\n\n.audio-player[data-sender=\"user\"] {\n    background-color: $main;\n}\n\n.audio-player[data-sender=\"user\"] audio::-webkit-media-controls-panel {\n    background-color: $main;\n    border-radius: 0px !important;\n}\n\n.audio-player[data-sender=\"user\"] audio::-webkit-media-controls-play-button,\n.audio-player[data-sender=\"user\"] audio::-webkit-media-controls-pause-button,\n.audio-player[data-sender=\"user\"] audio::-webkit-media-controls-timeline,\n.audio-player[data-sender=\"user\"] audio::-webkit-media-controls-mute-button,\n.audio-player[data-sender=\"user\"] audio::-webkit-media-controls-unmute-button,\n.audio-player[data-sender=\"user\"] audio::-webkit-media-controls-settings-button,\n.audio-player[data-sender=\"user\"] audio::-webkit-media-controls-overflow-menu-button,\n.audio-player[data-sender=\"user\"] audio::-webkit-media-controls-volume-slider {\n    filter: invert(1);\n    box-shadow: none !important;\n    filter: drop-shadow(none);\n}\n\n.audio-player[data-sender=\"user\"] audio::-webkit-media-controls-current-time-display,\n.audio-player[data-sender=\"user\"] audio::-webkit-media-controls-time-remaining-display {\n    all: unset;\n    color: #fff;\n    box-shadow: 0;\n    font-family: \"Gordita\";\n}\n","$main:#6892ff;\n$iacolor:#4CAF50;\n$primary:#ffffff;\n$secondary: #EAEAEA;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
