import Cookies from "js-cookie";
import { authStore } from "../../stores/auth-store";

export const Home = () =>{

const sair = ()=>{
    authStore.setAccessToken('');
    authStore.setRefreshToken('');
    Cookies.remove("accesstoken");
    Cookies.remove("refreshtoken");
    window.location.reload();
}
    return(<div>
        Home
    </div>)

}