import React from 'react';
import './contact.scss';

const ContactItem = ({ contact, handleDelete, handleEdit }) => {
  console.log(contact)
  return (
    <tr>
      <td>{contact.fullName}</td>
      <td>{contact.cellPhone}</td>
      <td>{contact.email}</td>
      <td>
        <button onClick={() => handleEdit(contact)}>Editar</button>
        <button onClick={() => handleDelete(contact)}>Excluir</button>
      </td>
    </tr>
  );
};

export default ContactItem;
