import { makeAutoObservable } from "mobx";

class AuthStore {
    constructor() {
        makeAutoObservable(this);
    }

    accesstoken = '';
    refreshtoken ='';

    setAccessToken(accesstoken) {
        this.accesstoken = accesstoken;
    }

    setRefreshToken(refreshtoken) {
        this.refreshtoken = refreshtoken;
    }
}

export const authStore = new AuthStore();