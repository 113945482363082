// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_customInput__brZxn * {
  border-radius: 8px !important;
}

.styles_customInput__brZxn *:focus {
  border-color: red !important;
}

.styles_customInputError__YFwuW {
  color: #d32f2f;
  font-size: 0.75rem;
  font-style: italic;
}`, "",{"version":3,"sources":["webpack://./src/components/input/styles.module.scss"],"names":[],"mappings":"AACA;EACI,6BAAA;AAAJ;;AAEA;EACI,4BAAA;AACJ;;AAEA;EACI,cAAA;EACA,kBAAA;EACA,kBAAA;AACJ","sourcesContent":["@import '../../styles/colors.scss';\n.customInput * {\n    border-radius: 8px !important;\n}\n.customInput *:focus{\n    border-color: red !important;\n}\n\n.customInputError {\n    color: #d32f2f;\n    font-size: 0.75rem;\n    font-style: italic;\n}\n\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"customInput": `styles_customInput__brZxn`,
	"customInputError": `styles_customInputError__YFwuW`
};
export default ___CSS_LOADER_EXPORT___;
