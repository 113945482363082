// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.confirmButton {
  background-color: #6892ff !important;
  color: white !important;
  border-radius: 8px !important;
}

.confirmButton:hover {
  background-color: #6892ff !important;
}`, "",{"version":3,"sources":["webpack://./src/components/button/styles.modules.scss"],"names":[],"mappings":"AAEA;EACI,oCAAA;EACA,uBAAA;EACA,6BAAA;AADJ;;AAIA;EACI,oCAAA;AADJ","sourcesContent":["@import '../../styles/colors.scss';\n\n.confirmButton{\n    background-color:$main !important;\n    color: white !important;\n    border-radius: 8px !important;\n}\n\n.confirmButton:hover{\n    background-color:$main !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
