// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_Suggestions__DLYFh {
  font-size: 0.8rem;
  height: 9rem;
  box-shadow: inset 0 -2px 8px -5px rgba(0, 0, 0, 0.5);
  display: flex;
}

.styles_suggestionsMessages__atoO5 {
  width: 75%;
  overflow: auto;
}

.styles_options__q4oxk {
  width: 25%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.styles_message__M\\+8z\\+ {
  cursor: pointer;
  border-radius: 5px;
  margin: 1rem 3rem 1rem 1rem;
  padding: 0.5rem;
  background-color: #6892ff;
  color: #ffffff;
}

.styles_toneOptions__XMzz8 {
  background-color: #ffffff;
  position: fixed;
  margin-top: -8rem;
  border-radius: 5px;
  width: 8rem;
}

.styles_toneOptions__XMzz8 div {
  margin: 0.5rem;
}

.styles_buttonTone__aHqbY {
  display: flex;
  cursor: pointer;
  justify-content: center;
  align-items: center;
}

.styles_buttonTone__aHqbY p {
  font-size: 1.1rem;
  padding-right: 1rem;
}

.styles_switchIcon__qKd3F {
  display: flex;
  align-items: center;
}

.styles_clear__NcMjU {
  display: flex;
  justify-content: center;
  color: #1e1e1e;
  margin: 1rem 3rem 1rem 1rem;
  cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/components/suggestions/styles.module.scss","webpack://./src/styles/colors.scss"],"names":[],"mappings":"AAEA;EACI,iBAAA;EACA,YAAA;EACA,oDAAA;EACA,aAAA;AADJ;;AAGA;EACA,UAAA;EAEA,cAAA;AADA;;AAIA;EACI,UAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,kBAAA;AADJ;;AAMA;EACI,eAAA;EACA,kBAAA;EACA,2BAAA;EACA,eAAA;EACA,yBC7BE;ED8BF,cAAA;AAHJ;;AAMA;EACI,yBAAA;EACA,eAAA;EACA,iBAAA;EACA,kBAAA;EACA,WAAA;AAHJ;;AAMA;EACI,cAAA;AAHJ;;AAMA;EACI,aAAA;EACA,eAAA;EACA,uBAAA;EACA,mBAAA;AAHJ;;AAMA;EACI,iBAAA;EACA,mBAAA;AAHJ;;AAOA;EACI,aAAA;EACA,mBAAA;AAJJ;;AAMA;EACI,aAAA;EACA,uBAAA;EACA,cAAA;EACA,2BAAA;EACA,eAAA;AAHJ","sourcesContent":["@import '../../styles/colors.scss';\n\n.Suggestions{\n    font-size: 0.8rem;\n    height: 9rem;\n    box-shadow: inset 0 -2px 8px -5px rgba(0, 0, 0, 0.5);\n    display: flex;\n}\n.suggestionsMessages{\nwidth: 75%;\n\noverflow: auto;\n}\n\n.options{\n    width: 25%;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    position: relative;\n    \n}\n\n\n.message{\n    cursor: pointer;\n    border-radius: 5px;\n    margin: 1rem 3rem 1rem 1rem;\n    padding: 0.5rem;\n    background-color: $main;\n    color: #ffffff;\n}\n\n.toneOptions{\n    background-color: #ffffff;\n    position: fixed;\n    margin-top: -8rem;\n    border-radius: 5px;\n    width: 8rem;\n}\n\n.toneOptions div{\n    margin: 0.5rem;\n}\n\n.buttonTone{\n    display: flex;\n    cursor: pointer;\n    justify-content: center;\n    align-items: center;\n}\n\n.buttonTone p{\n    font-size: 1.1rem;\n    padding-right: 1rem;\n    \n}\n\n.switchIcon{\n    display: flex;\n    align-items: center;\n}\n.clear{\n    display: flex;\n    justify-content: center;\n    color: #1e1e1e;\n    margin: 1rem 3rem 1rem 1rem;\n    cursor: pointer;\n}","$main:#6892ff;\n$iacolor:#4CAF50;\n$primary:#ffffff;\n$secondary: #EAEAEA;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Suggestions": `styles_Suggestions__DLYFh`,
	"suggestionsMessages": `styles_suggestionsMessages__atoO5`,
	"options": `styles_options__q4oxk`,
	"message": `styles_message__M+8z+`,
	"toneOptions": `styles_toneOptions__XMzz8`,
	"buttonTone": `styles_buttonTone__aHqbY`,
	"switchIcon": `styles_switchIcon__qKd3F`,
	"clear": `styles_clear__NcMjU`
};
export default ___CSS_LOADER_EXPORT___;
