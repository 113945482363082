import { makeAutoObservable } from "mobx";

class ChatStore {
    viewMessages = [];
    constructor() {
        makeAutoObservable(this);
    }


    setViewMessages(viewMessages) {
        this.viewMessages = viewMessages;
    }

    setPhoneNumber(phoneNumber){
        this.phoneNumber=phoneNumber;
    }

    setUnknownWs(connection){
        this.wsConnection=connection;
    }

    setUnknownMessages(messages){
        this.unknownMessages=messages;
    }

}

export const chatStore = new ChatStore();