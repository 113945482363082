import { useEffect, useState } from "react";
import { w3cwebsocket as W3CWebSocket } from "websocket";
import { chatStore } from "../stores/chat-store";

export const useUnknownChat = (phone) => {
  const [targetPhone, setTargetPhone] = useState(phone);
  const [client, setClient] = useState(chatStore.wsConnection);
  const [messages, setMessages] = useState([]);

  useEffect(() => {
    setTargetPhone(phone);
  }, [phone]);

  useEffect(() => {
    if (client) {
      client.close();
      console.log("conexão websocket UNKNOWN fechada");
    }

    const newClient = new W3CWebSocket(`${process.env.REACT_APP_WS_URL}/ws/chat/unknown`);
    chatStore.setUnknownWs(newClient);
    setClient(newClient);

    // Clean up function
    return () => {
      newClient.close();
    };
  }, []);

  useEffect(() => {
    if (client) {
      client.onopen = () => {
        console.log("Conexão websocket UNKNOWN estabelecida.");
      };

      client.onmessage = (message) => {
        const receivedMessage = JSON.parse(message.data);
        const targetNumber = receivedMessage.message.targetNumber;

        // Verifica se o targetNumber já existe na array de messages
        if (!messages.some(message => message.targetNumber === targetNumber)) {
          setMessages((prevMessages) => [...prevMessages, receivedMessage.message]);
        }

        // Verifica se o número da mensagem recebida corresponde ao número selecionado
        if (targetPhone && targetNumber === targetPhone.phone) {
          const msg = {
            "sender": receivedMessage.message.targetNumber,
            "text": receivedMessage.message.content,
            "role": receivedMessage.message.sender,
            "mediaType": receivedMessage.message.mediaType,
            "mediaUrl": receivedMessage.message.mediaUrl
          };

          if (msg.text !== undefined) {
            chatStore.setViewMessages([...chatStore.viewMessages, msg]);
          }
        }
      };
    }
  }, [client, messages, targetPhone]);

  const receiveMessage = () => {
    // Lógica adicional aqui, se necessário
  };

  return { messages, receiveMessage };
};
