// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_chatbg__DOo5H {
  display: flex;
  height: 100%;
  width: 100%;
}

.styles_chatgroup__VhXIr {
  height: 100%;
  width: 30%;
  max-width: 40%;
}

.styles_noChatContainer__Q-QFv {
  height: 100%;
  width: 60%;
  max-width: 60%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.styles_iconChat__6-HMZ {
  height: 100%;
}

.styles_iconText__xEPva {
  height: 50%;
}
.styles_iconText__xEPva p {
  text-align: center;
  color: #CECECE;
}`, "",{"version":3,"sources":["webpack://./src/pages/chats/styles.module.scss"],"names":[],"mappings":"AACA;EACI,aAAA;EACA,YAAA;EACA,WAAA;AAAJ;;AAEA;EACI,YAAA;EACA,UAAA;EACA,cAAA;AACJ;;AAEA;EACI,YAAA;EACA,UAAA;EACA,cAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;AACJ;;AAEA;EACI,YAAA;AACJ;;AAEA;EACI,WAAA;AACJ;AAAI;EACI,kBAAA;EACA,cAAA;AAER","sourcesContent":["\n.chatbg{\n    display: flex;\n    height: 100%;\n    width: 100%;\n}\n.chatgroup{\n    height: 100%;\n    width: 30%;\n    max-width: 40%;\n}\n\n.noChatContainer{\n    height: 100%;\n    width: 60%;\n    max-width: 60%;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    \n}\n.iconChat{\n    height: 100%;\n}\n\n.iconText{\n    height: 50%;\n    p{\n        text-align: center;\n        color: #CECECE;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"chatbg": `styles_chatbg__DOo5H`,
	"chatgroup": `styles_chatgroup__VhXIr`,
	"noChatContainer": `styles_noChatContainer__Q-QFv`,
	"iconChat": `styles_iconChat__6-HMZ`,
	"iconText": `styles_iconText__xEPva`
};
export default ___CSS_LOADER_EXPORT___;
