import React, { useRef } from 'react';
import { IconButton, TextField, InputAdornment, Box } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import styles from './styles.module.scss';

export const Notes = ({ notes, setNotes, onNoteSubmit }) => {
    const inputRef = useRef(null);

    const handleInputChange = (event) => {
        setNotes(event.target.value);
    };

    const handleFormSubmit = (event) => {
        event.preventDefault();
        onNoteSubmit(notes); 
        setNotes('');  // Limpa o estado 'notes' para uma string vazia
    };

    return (
        <Box className={styles.chatInputContainer}>
            <form onSubmit={handleFormSubmit}>
                <TextField
                    inputRef={inputRef}
                    multiline
                    rows={1}
                    variant="outlined"
                    value={notes}
                    onChange={handleInputChange}
                    placeholder="Digite uma anotação"
                    className={styles.inputField}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton type='submit'>
                                    <SendIcon />
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                />
            </form>
        </Box>
    );
};
