// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_body__M\\+zU5 {
  height: 100vh;
  width: 100%;
  background-color: #6892ff;
  display: flex;
  overflow: hidden;
}

.styles_bg__cI-xz {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.styles_navbar__RGBCP {
  width: 5rem;
  transition: 0.5s;
}

.styles_openNavbar__VYJjO {
  width: 22rem !important;
  transition: 0.5s;
}`, "",{"version":3,"sources":["webpack://./src/components/container/styles.module.scss","webpack://./src/styles/colors.scss"],"names":[],"mappings":"AAEA;EACE,aAAA;EACA,WAAA;EACA,yBCLI;EDMJ,aAAA;EACA,gBAAA;AADF;;AAIA;EACE,WAAA;EACA,aAAA;EACA,yBAAA;AADF;;AAIA;EACE,WAAA;EACA,gBAAA;AADF;;AAIA;EACE,uBAAA;EACA,gBAAA;AADF","sourcesContent":["@import '../../styles/colors.scss';\n\n.body {\n  height: 100vh;\n  width: 100%;\n  background-color: $main;\n  display: flex;\n  overflow: hidden;  // Evita a expansão da tela\n}\n\n.bg {\n  width: 100%;\n  display: flex;\n  justify-content: flex-end;\n}\n\n.navbar {\n  width: 5rem;\n  transition: 0.5s;\n}\n\n.openNavbar {\n  width: 22rem !important;\n  transition: 0.5s;\n}\n","$main:#6892ff;\n$iacolor:#4CAF50;\n$primary:#ffffff;\n$secondary: #EAEAEA;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"body": `styles_body__M+zU5`,
	"bg": `styles_bg__cI-xz`,
	"navbar": `styles_navbar__RGBCP`,
	"openNavbar": `styles_openNavbar__VYJjO`
};
export default ___CSS_LOADER_EXPORT___;
