import React, { useEffect, useState } from 'react';
import './UserList.scss';
import { Delete, Edit, Search } from '@mui/icons-material';
import { UserRegister } from '../userRegister';
import { UserEdit } from '../userEdit';
import { UserExclude } from '../userDelete';
import { UserView } from '../userView';
import useUserServices from '../../services/user';
import Cookies from 'js-cookie';

export const UserList = () => {
  const [showRegister, setShowRegister] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [userId, setUserId] = useState(null); 
  const [showExclude, setShowExclude] = useState(false);
  const [showUser, setShowUser] = useState(false);
  const [allUsers, setAllUsers] = useState([]);
  const [currentPage, setCurrentPage] = useState(1); 
  const usersPerPage = 7; 
  const { userGetAll } = useUserServices();

  const toggleRegister = () => {
    setShowRegister(!showRegister);
  };

  const toggleEdit = (userId) => { 
    setUserId(userId); 
    setShowEdit(!showEdit);
    if (showUser) {
        setShowUser(false);
    }
  };

  const toggleExclude = (userId) => { 
    setUserId(userId); 
    setShowExclude(!showExclude);
    if (showUser) {
      setShowUser(false);
  }
  };

  const toggleUser = (userId) => { 
    setUserId(userId); 
    setShowUser(!showUser);
  };

  useEffect(() => {
    const handleViewUsers = async () => {
      const response = await userGetAll({"Authorization":`Bearer ${Cookies.get("accesstoken")}`});
      setAllUsers(response.data);
    };
    handleViewUsers();
  }, []);

  // Calcular número total de páginas
  const totalPages = Math.ceil(allUsers.length / usersPerPage);

  // Função para mudar para a página específica
  const goToPage = (page) => {
    setCurrentPage(page);
  };

  // Gerar os botões de página
  const pageNumbers = [];
  for (let i = 1; i <= totalPages; i++) {
    pageNumbers.push(i);
  }

  return (
    <div className="user-list-container">
      {showRegister && <UserRegister toggleRegister={toggleRegister} />}
      {showEdit && <UserEdit toggleEdit={toggleEdit} userId={userId} />}
      {showExclude && <UserExclude toggleExclude={toggleExclude} userId={userId} />}
      {showUser && <UserView toggleView={toggleUser} toggleEdit={toggleEdit} toggleExclude={toggleExclude} userId={userId} />}

      <div className="user-list-content">
        <h2 className='h2'>Lista de usuários</h2>
        <div className="searchcontainer">
          <div className="search-bar">
            <input type="text" placeholder="Pesquisar usuário..." />
            <Search className="search-icon" />
          </div>
        </div>
        <table>
          <thead>
            <tr>
              <th>Nome</th>
              <th>Email</th>
              <th>Editar</th>
              <th>Excluir</th>
            </tr>
          </thead>
          <tbody>
            {allUsers
              .slice((currentPage - 1) * usersPerPage, currentPage * usersPerPage)
              .map((user, index) => (
                <tr key={index}>
                  <td>
                    <div className="user-info" onClick={() => toggleUser(user.id)}>
                      <div className="user-avatar"></div>
                      <span>{user.name}</span>
                    </div>
                  </td>
                  <td>{user.email}</td>
                  <td className="edittd">
                    <button className="edit-button" onClick={() => toggleEdit(user.id)}><Edit /></button>
                  </td>
                  <td className="excludetd">
                    <button className="delete-button" idser={user.id} onClick={() => toggleExclude(user.id)}><Delete /></button>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
        <div className="pagination">
          {pageNumbers.map((number) => (
            <button 
              key={number} 
              className={currentPage === number ? "page-number selected" : "page-number"} 
              onClick={() => goToPage(number)}
            >
              {number}
            </button>
          ))}
        </div>
      </div>
      <div className="add">
        <button className="addbutton" onClick={toggleRegister}>+</button>
      </div>
    </div>
  );
};
