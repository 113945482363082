import useAxios from "./api";

const useAuthService = () => {
    const {api} = useAxios();

    return {
        authentication: async(data) => {
            return await api.post('/api/token', data);
        },

        userName: async(data, header)=>{
            return await api.get(`/api/auth/useremail/${data}`,{headers:header})
        },
        isLoged: async (token) => {
            return await api.post('/api/token/verify', {token: token})
        },
    };
};

export default useAuthService;