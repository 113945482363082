import styles from './style.module.scss';

export const Message = ({ text, hour, sender }) => {
    const renderTextWithBold = (text) => {
        const parts = text.split(/(\*.*?\*)/);
        return parts.map((part, index) => {
            if (part.startsWith('*') && part.endsWith('*')) {
                return <strong key={index}>{part.slice(1, -1)}</strong>;
            }
            return part;
        });
    };

    const messageLines = text.split('\n');
    
    return (
        <div data-sender-type={sender} className={styles.message}>
            <p>
                {messageLines.map((line, index) => (
                    <span key={index}>
                        {renderTextWithBold(line)}
                        {index < messageLines.length - 1 && <br />}
                    </span>
                ))}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <span className={styles.hour}>{hour}</span>
            </p>
        </div>
    );
};
