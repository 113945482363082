// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.contact-form {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 5px;
}

.contact-form h2 {
  text-align: center;
  margin-bottom: 20px;
}

.form-group {
  margin-bottom: 15px;
}

.form-group label {
  display: block;
  font-weight: bold;
  margin-bottom: 5px;
}

.form-group input,
.form-group select,
.form-group textarea {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.form-group textarea {
  resize: vertical;
}

.checkbox-group {
  display: flex;
  align-items: center;
}

.checkbox-group label {
  margin-left: 5px;
  font-weight: normal;
}

.form-buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.form-buttons button {
  max-width: 45%;
  padding: 10px 15px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.form-buttons button[type=submit] {
  background-color: #28a745;
  color: white;
}

.form-buttons button[type=button] {
  background-color: #dc3545;
  color: white;
}

.empty-form {
  height: 40px;
}`, "",{"version":3,"sources":["webpack://./src/components/contact/contactform.scss"],"names":[],"mappings":"AAAA;EACI,gBAAA;EACA,cAAA;EACA,aAAA;EACA,yBAAA;EACA,sBAAA;EACA,kBAAA;AACJ;;AAEE;EACE,kBAAA;EACA,mBAAA;AACJ;;AAEE;EACE,mBAAA;AACJ;;AAEE;EACE,cAAA;EACA,iBAAA;EACA,kBAAA;AACJ;;AAEE;;;EAGE,WAAA;EACA,YAAA;EACA,sBAAA;EACA,kBAAA;AACJ;;AAEE;EACE,gBAAA;AACJ;;AAEE;EACE,aAAA;EACA,mBAAA;AACJ;;AAEE;EACE,gBAAA;EACA,mBAAA;AACJ;;AAEE;EACE,aAAA;EACA,8BAAA;EACA,gBAAA;AACJ;;AAEE;EACE,cAAA;EACA,kBAAA;EACA,YAAA;EACA,kBAAA;EACA,eAAA;AACJ;;AAEE;EACE,yBAAA;EACA,YAAA;AACJ;;AAEE;EACE,yBAAA;EACA,YAAA;AACJ;;AAEE;EACE,YAAA;AACJ","sourcesContent":[".contact-form {\n    max-width: 600px;\n    margin: 0 auto;\n    padding: 20px;\n    background-color: #f9f9f9;\n    border: 1px solid #ddd;\n    border-radius: 5px;\n  }\n  \n  .contact-form h2 {\n    text-align: center;\n    margin-bottom: 20px;\n  }\n  \n  .form-group {\n    margin-bottom: 15px;\n  }\n  \n  .form-group label {\n    display: block;\n    font-weight: bold;\n    margin-bottom: 5px;\n  }\n  \n  .form-group input,\n  .form-group select,\n  .form-group textarea {\n    width: 100%;\n    padding: 8px;\n    border: 1px solid #ccc;\n    border-radius: 4px;\n  }\n  \n  .form-group textarea {\n    resize: vertical;\n  }\n  \n  .checkbox-group {\n    display: flex;\n    align-items: center;\n  }\n  \n  .checkbox-group label {\n    margin-left: 5px;\n    font-weight: normal;\n  }\n  \n  .form-buttons {\n    display: flex;\n    justify-content: space-between;\n    margin-top: 20px;\n  }\n  \n  .form-buttons button {\n    max-width: 45%;\n    padding: 10px 15px;\n    border: none;\n    border-radius: 4px;\n    cursor: pointer;\n  }\n  \n  .form-buttons button[type=\"submit\"] {\n    background-color: #28a745;\n    color: white;\n  }\n  \n  .form-buttons button[type=\"button\"] {\n    background-color: #dc3545;\n    color: white;\n  }\n  \n  .empty-form {\n    height: 40px;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
