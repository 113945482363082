// chatgroup.js (Serviço de conexão)
import useAxios from "./api";

export const useChatGroupService = () => {
    const { api } = useAxios();
    return {
        getAllGroups: async () => {
            try {
                return await api.get(`/api/chat/external/chatgroups`);
            } catch (error) {
                console.error("Error fetching groups:", error);
                return { data: [] };
            }
        },

        postGroup: async (data) => {
            try {
                return await api.post(`/api/chat/external/chatgroups`, data);
                alert("Grupo criado com sucesso");
            } catch (error) {
                console.error("Error posting group:", error);
                alert("Erro ao criar grupo");
            }
        },
        putGroup: async (id, data) => {
            try {
                return await api.put(`/api/chat/external/chatgroups/${id}`, data);
            } catch (error) {
                console.error("Error updating group:", error);
                return null;
            }
        },
        deleteGroup: async (id) => {
            try {
                return await api.delete(`/api/chat/external/chatgroups/${id}`);
            } catch (error) {
                console.error("Erro ao excluir grupo", error);
                alert("Não é possível excluir esse Grupo");
            }
        }
    }
}
