// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@charset "UTF-8";
.styles_chatInputContainer__PsR3k {
  padding: 0; /* Removendo padding */
  border-radius: 4px;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  box-sizing: border-box; /* Certifica-se de que o padding seja considerado no tamanho total */
  position: relative; /* Adiciona posição relativa para posicionamento correto do emoji picker */
}

form {
  width: 100%;
}

.styles_inputField__KT1eb {
  border-radius: 4px;
  flex-grow: 1;
  background-color: #F0EC91;
  width: 100%; /* Ajustando a largura para 100% */
  margin: 0; /* Removendo margin */
}
.styles_inputField__KT1eb .styles_MuiOutlinedInput-root__LBH\\+U {
  background-color: #F0EC91;
  box-sizing: border-box; /* Certifica-se de que o padding seja considerado no tamanho total */
  height: 100%; /* Garante que o campo de entrada ocupe 100% da altura */
  padding: 0; /* Removendo padding */
}
.styles_inputField__KT1eb .styles_MuiOutlinedInput-notchedOutline__69\\+2J {
  border-color: #F0EC91; /* Ajuste a cor da borda se necessário */
}`, "",{"version":3,"sources":["webpack://./src/components/notes/styles.module.scss"],"names":[],"mappings":"AAAA,gBAAgB;AAAhB;EACI,UAAA,EAAA,sBAAA;EACA,kBAAA;EACA,WAAA;EACA,YAAA;EACA,aAAA;EACA,mBAAA;EACA,sBAAA,EAAA,oEAAA;EACA,kBAAA,EAAA,0EAAA;AAEJ;;AACE;EACE,WAAA;AAEJ;;AACE;EACE,kBAAA;EACA,YAAA;EACA,yBAAA;EACA,WAAA,EAAA,kCAAA;EACA,SAAA,EAAA,qBAAA;AAEJ;AADI;EACE,yBAAA;EACA,sBAAA,EAAA,oEAAA;EACA,YAAA,EAAA,wDAAA;EACA,UAAA,EAAA,sBAAA;AAGN;AADI;EACE,qBAAA,EAAA,wCAAA;AAGN","sourcesContent":[".chatInputContainer {\n    padding: 0; /* Removendo padding */\n    border-radius: 4px;\n    width: 100%;\n    height: 100%;\n    display: flex;\n    align-items: center;\n    box-sizing: border-box; /* Certifica-se de que o padding seja considerado no tamanho total */\n    position: relative; /* Adiciona posição relativa para posicionamento correto do emoji picker */\n  }\n\n  form{\n    width: 100%;\n  }\n  \n  .inputField {\n    border-radius: 4px;\n    flex-grow: 1;\n    background-color: #F0EC91;\n    width: 100%; /* Ajustando a largura para 100% */\n    margin: 0; /* Removendo margin */\n    .MuiOutlinedInput-root {\n      background-color: #F0EC91;\n      box-sizing: border-box; /* Certifica-se de que o padding seja considerado no tamanho total */\n      height: 100%; /* Garante que o campo de entrada ocupe 100% da altura */\n      padding: 0; /* Removendo padding */\n    }\n    .MuiOutlinedInput-notchedOutline {\n      border-color: #F0EC91; /* Ajuste a cor da borda se necessário */\n    }\n  }\n "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"chatInputContainer": `styles_chatInputContainer__PsR3k`,
	"inputField": `styles_inputField__KT1eb`,
	"MuiOutlinedInput-root": `styles_MuiOutlinedInput-root__LBH+U`,
	"MuiOutlinedInput-notchedOutline": `styles_MuiOutlinedInput-notchedOutline__69+2J`
};
export default ___CSS_LOADER_EXPORT___;
