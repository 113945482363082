import styles from './styles.module.scss';

import logo from '../../assets/logo.png';
export const Lgpd = () =>{
    return(
    <div className={styles.body}>
        <header>
            <div className={styles.logo}>
            <img src={logo} alt="" className={styles.icone} />
            <h1>MaximizeAi</h1>
            </div>
            <div className={styles.title}>
                Termos de consentimento
            </div>
        </header>
        <main>
            <div className={styles.subtitle}>
            Antes de fazermos os orçamentos precisamos do seu consentimento para usarmos seus dados. O consentimento informado decorre da LGPD conforme descrevemos a seguir:
            </div>
            <hr />
            <div className={styles.topText}>
            A 7Ases Seguros informa que seus dados são protegidos nos termos da Lei n. 13.709
            (LGPD - Lei Geral de Proteção de Dados) e em virtude de nossa atuação como corretora de seguros, e da obrigação imposta pelo artigo 7º,
            inciso I e parágrafo 5º da referida lei, precisamos de seu consentimento expresso para armazenar, usar
            e compartilhar seus dados pessoais e/ou sensíveis com as seguradoras, empresas de inteligência artificial - IA, tais
            como OpenAI, Meta e Groq, bem como demais empresas parceiras, com o fim específico de cotar e intermediar a contratação de seguro automóvel, bem como intermediar atendimento de sinistros e assistências 24h através de empresas
            terceirizadas caso venha a contratar o seguro conosco.
            </div>
            <div className={styles.topText}>
            Seu consentimento será válido por TEMPO INDETERMINADO, podendo ser revogado expressamente a qualquer momento, e está sendo feito neste momento de forma livre, espontânea, inequívoca, expressa e informada. Desta forma,
            a 7Ases poderá, através de qualquer meio de comunicação, tais como WhastApp, e-mail, correio, telefone, entre outros, usar e compartilhar dados pessoais e/ou sensíveis,
            como dados de documentos fornecidos, informação sobre PCD, dados biométricos, dados e declaração pessoal, etc, com a finalidade de fazer cotação de seguro automóvel em diversas seguradoras, 
            enviar informações para todas as empresas com as quais a 7Ases possui relação comercial, bem como processar a informação com uso de IA, para atingir tal finalidade, bem como armazenar e fazer a gestão dos contratos.
            </div>
        </main>
    </div>
    )
}