import axios from "axios";

const useAxios = () =>{
    
    const api = axios.create({
        baseURL: process.env.REACT_APP_API_URL
    });
    return {api}
};

export default useAxios;