import { useState } from 'react';
import { TextField, IconButton, InputAdornment } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import styles from './styles.module.scss';

function CustomInput({ type, id, label, name, autoComplete, value, onChange, helperText, error }) {
    const [showPassword, setShowPassword] = useState(false);

    const handleTogglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const showEyeIcon = type === 'password';

    return (
        <TextField
            className={styles.customInput}
            type={showPassword ? "text" : type}
            variant="outlined"
            fullWidth
            
            error={error}
            FormHelperTextProps={{ className: error ? styles.erroMensagem : '' }}
            helperText={helperText}
            id={id}
            label={label}
            name={name}
            autoComplete={autoComplete}
            value={value}
            onChange={onChange}
            InputProps={showEyeIcon && {
                endAdornment: (
                    <InputAdornment position="end">
                        <IconButton onClick={handleTogglePasswordVisibility} edge="end">
                            {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                        </IconButton>
                    </InputAdornment>
                ),
            }}
        />
    );
}

export default CustomInput;
