import React, { useEffect, useState } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { useChatGroupService } from '../../services/chatgroup';
import { DraggableChatGroupItem } from '../DraggableChatGroupItem';
import styles from './styles.module.scss';
import Cookies from "js-cookie";

export const ChatContainerGroup = ({ toggleGroup, toggleCustomer, unknown }) => {
  const { getAllGroups, putGroup } = useChatGroupService();
  const [allGroups, setAllGroups] = useState([]);
  const userId = Cookies.get("idName");
  useEffect(() => {
    const fetchGroups = async () => {
      const response = await getAllGroups();
      if (response && response.data) {
        setAllGroups(response.data);
        console.log("Fetched groups:", response.data);
      }
    };
    fetchGroups();
  }, []);

  const moveChatGroup = async (fromPosition, toPosition) => {
    console.log(`Movendo de ${fromPosition} para ${toPosition}`);
    const updatedGroups = allGroups.map((group) => {
      if (group.chat_position === fromPosition) return { ...group, chat_position: toPosition };
      if (group.chat_position === toPosition) return { ...group, chat_position: fromPosition };
      return group;
    });

    const reorderedGroups = updatedGroups
      .sort((a, b) => a.chat_position - b.chat_position)
      .map((group, index) => ({ ...group, chat_position: index }));

    console.log('Grupos reordenados:', reorderedGroups);

    setAllGroups(reorderedGroups);

    try {
      for (const group of reorderedGroups) {
        const result = await putGroup(group.chat_id, {
          chat_position: group.chat_position,
          chat_name: group.chat_name,
          chat_numbers: group.chat_numbers
        });
        console.log(`Atualizando grupo ${group.chat_id}:`, result);
      }
    } catch (error) {
      console.error("Erro ao atualizar a posição do grupo de chat:", error);
    }
  };

  return (
    
    <DndProvider backend={HTML5Backend}>
  <div className={styles.chatContainerGroup}>
    {allGroups
      .sort((a, b) => a.chat_position - b.chat_position)
      .map(chatGroup => (
        (chatGroup.chat_owner === userId || chatGroup.chat_owner ===null) && (
          <DraggableChatGroupItem
            key={chatGroup.chat_id}
            chatGroup={chatGroup}
            moveChatGroup={moveChatGroup}
            toggleCustomer={toggleCustomer}
            unknown={unknown}
          />
        )
      ))}
    <div className={styles.newGroup} onClick={toggleGroup}>
      +
    </div>
  </div>
</DndProvider>

  );
};
