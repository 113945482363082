import { Grid, Typography, Container } from '@mui/material';
import CustomButton from './button';
import CustomInput from './input';
import { useFormik } from 'formik';
import redireciona from '../assets/redireciona.png'
import useAuthService from "../services/auth";
import { authStore } from "../stores/auth-store";
import * as Yup from 'yup';
import { formikProps } from '../utils/formikProps';
import { useNavigate } from 'react-router-dom';
import Cookies from "js-cookie";
import {toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { UserRegister } from './userRegister';
import { useState } from 'react';

export const LoginForm =({ onForgotPasswordClick, setEmail, email })=>{
  const [openUserRegister, setOpenUserRegister] = useState (false)
  const { authentication, userName } = useAuthService();
  const navigate= useNavigate();
  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email('E-mail inválido')
        .required('O e-mail é obrigatório'),
      password: Yup.string()
        .required('A senha é obrigatória'),
    }),
    onSubmit: () => {
      authentication(formik.values)
        .then((resp) => {
          authStore.setAccessToken(resp.data.access);
          authStore.setRefreshToken(resp.data.refresh);
          Cookies.set("accesstoken", resp.data.access);
          Cookies.set("refreshtoken", resp.data.refresh);
          Cookies.set("refreshtoken", resp.data.refresh);
          userName(email, {"Authorization":`Bearer ${resp.data.access}`}).then((response)=>{
            Cookies.set("userName", response.data.name);
            Cookies.set("idName", response.data.id);
          })
          navigate("/home")
        })
        .catch(() => {
          toast.error("Email e/ou senha incorretos!");
        });
    },

  });

  const handleEmailChange = (event) => {
    formik.handleChange(event)
    setEmail(event.target.value)
  };

  return (
    <Container component="main" maxWidth="xs" style={{ height: '100%' }}>
      <UserRegister isOpen={openUserRegister} setUserRegisterOpen={() => setOpenUserRegister(!openUserRegister)} />
      <div className='loginBody'>
        <Typography className='labelgeral' component="h1" variant="h4">
          Faça seu login
        </Typography>
        <form onSubmit={formik.handleSubmit}>
          <div className='inputs' container>
            <Grid item xs={12} className='p-1'>
              <CustomInput
                type="text"
                id="email"
                label="Digite aqui o seu email"
                name="email"
                autoComplete="email"
                {...formikProps("email", formik)}
                onChange={handleEmailChange}
                size={"small"}
              />
            </Grid>
            <Grid item xs={12}>
              <CustomInput
                type="password"
                id="password"
                label="Digite aqui a sua senha"
                name="password"
                autoComplete="password"
                {...formikProps("password", formik)}
                size={"small"}
              />
            </Grid>
          </div>
          <Grid container spacing={2} py={4}>
            <Grid item xs={6}>
              <CustomButton text="Entrar" type="submit" />
            </Grid>
            <Grid item xs={6}>
              <CustomButton onClick={() => setOpenUserRegister(true)} text="Registrar" type="submit" />
            </Grid>
          </Grid>
          <p onClick={onForgotPasswordClick} className="forgotpassword">
                Esqueci minha senha
                <img src={redireciona} alt="redirecionar" />
              </p>
        </form>
      </div>      
    </Container>
  );
}