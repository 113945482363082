import { Navigate, Outlet } from "react-router-dom";
import Cookies from "js-cookie";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import useAuthService from "../../services/auth";

export const PrivateRoutes = observer(() => {
    const authService = useAuthService();
    const token = Cookies.get("accesstoken") || "";
    const [loged, setLoged] = useState(false);

    const handleAuth = async () => {
        try {
            const resp = await authService.isLoged(token);
            setLoged(resp.data.status);
        } catch (error) {
        }
    };


    useEffect(() => {
        handleAuth();
    }, [token]);
    return token ? <Outlet /> : <Navigate to="/login" />;
})

