import axios from "axios";

const useFileAxios = () => {
    const fileApi = axios.create({
        baseURL: "http://144.126.158.223"
    });
    return { fileApi };
}

export default useFileAxios;
