// ResetPassword.jsx
import React from 'react'
import lock from '../assets/cadeado.png'
import CustomButton from './button';
import { Link } from 'react-router-dom';
function goBack() {
  window.location.reload();
}

function ResetPassword() {
  return (
    <div class="reserpassword">
      <p class='titlepassword'><img src={lock} className='lock' alt="cadeado" />Redefinição de senha solicitada</p>
      <p className='passwordtext'>Instruções de redefinição enviadas para o e-mail. Verifique a caixa de entrada/spam ou solicite novamente, se necessário.</p>
      <div className="passwordbtn">
        <CustomButton text="OK" onClick={goBack}/>
        </div>
    </div>
  );
}

export default ResetPassword;