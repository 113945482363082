import { OpenBar } from './abrir';
import styles from './styles.module.scss';
import React, { useState } from 'react';
import { InternalChats } from '../internalchats';
import up from '../../assets/up.png'
import RightSidebar from '../RightSidebar';

export const Background = ({ onClick, children, visibility }) => {
    const [chatVisible, setChatVisible] = useState(true);

    const toggleChatVisibility = () => {
        setChatVisible(!chatVisible); 
    };

    return (
        <div className={styles.bg}>
            <div className={styles.menu}>
                <OpenBar onClick={onClick} visibility={visibility}/>
            </div>
            <div className={styles.content}>
                {children}
            </div>
            {!chatVisible && ( 
                <div className={styles.internalchat}>
                    <InternalChats toggleChatVisibility={toggleChatVisibility} />
                </div>
            )}
            {chatVisible && (
                <button className={styles.buttonChat} onClick={toggleChatVisibility}>
                    <div className={styles.btncontent}>
                        <img src={up} alt="" />
                        <p>Online</p>
                    </div>
                </button>
            )}
        </div>
    );
};