import React, { useRef, useState, useEffect } from 'react';
import { Box, IconButton, Typography } from '@mui/material';
import StopIcon from '@mui/icons-material/Stop';
import CloseIcon from '@mui/icons-material/Close';
import SendIcon from '@mui/icons-material/Send';
import styles from './styles.module.scss';

const AudioRecorder = ({ onClose, onSend }) => {
    const [audioURL, setAudioURL] = useState(null);
    const mediaRecorderRef = useRef(null);
    const audioChunksRef = useRef([]);

    useEffect(() => {
        const startRecording = async () => {
            try {
                const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
                const mediaRecorder = new MediaRecorder(stream);
                mediaRecorderRef.current = mediaRecorder;
                
                mediaRecorder.ondataavailable = event => {
                    audioChunksRef.current.push(event.data);
                };

                mediaRecorder.onstop = () => {
                    const audioBlob = new Blob(audioChunksRef.current);
                    const audioUrl = URL.createObjectURL(audioBlob);
                    setAudioURL(audioUrl);
                };
                

                mediaRecorder.start();
            } catch (error) {
                console.error('Error accessing the microphone', error);
            }
        };

        startRecording();

        return () => {
            if (mediaRecorderRef.current && mediaRecorderRef.current.state !== "inactive") {
                mediaRecorderRef.current.stop();
            }
        };
    }, []);

    const stopRecording = () => {
        if (mediaRecorderRef.current) {
            mediaRecorderRef.current.stop();
        }
    };

    const handleAudioSend = () => {
        if (audioURL) {
            onSend(audioURL);
            setAudioURL(null);
            onClose();
        }
    };

    return (
        <Box className={styles.audioRecorderContainer}>
            <CloseIcon className={styles.closeAudioRecorderButton} onClick={onClose} />
            {audioURL ? (
                <div className={styles.audioPreview}>
                    <audio controls src={audioURL}></audio>
                    <div className={styles.sendExcludeAudio}>
                        <IconButton onClick={handleAudioSend}>
                            <SendIcon />
                        </IconButton>
                    </div>
                </div>
            ) : (
                <Box className={styles.audioRecording}>
                    <Typography variant="body2">Recording audio
                        <span className={styles.dots}>
                            <span className={styles.dot}></span>
                            <span className={styles.dot}></span>
                            <span className={styles.dot}></span>
                        </span>
                    </Typography>
                    <IconButton onClick={stopRecording}>
                        <StopIcon />
                    </IconButton>
                </Box>
            )}
        </Box>
    );
};

export default AudioRecorder;
