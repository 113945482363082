import React, { useEffect, useState } from 'react';
import styles from './styles.module.scss';
import { Settings, Close } from '@mui/icons-material';
import { useChatGroupService } from '../../services/chatgroup';
import useCustomerService from '../../services/customers';
import Cookies from 'js-cookie';
import { useUnknownServices } from '../../services/unknown';
import { GroupRegister } from '../../components/newChatGroup';

export const ChatGroupItem = ({ chatGroup, unknown, toggleCustomer }) => {
  const [customerNames, setCustomerNames] = useState({});
  const { customerPhone } = useCustomerService({ "Authorization": `Bearer ${Cookies.get("accesstoken")} `});
  const [optionsVisibility, setOptionsVisibility] = useState(false);
  const [options, setOptions] = useState(false);
  const [showGroup, setShowGroup] = useState(false);
  const [unknownNumber, setUnknownNumber] = useState([]);
  const { unknownGetAll } = useUnknownServices({ "Authorization": `Bearer ${Cookies.get("accesstoken")} `});
  const phoneNumbersArray = chatGroup.chat_numbers.split(',').map(phone => phone.trim());
  const { deleteGroup } = useChatGroupService();

  const fetchUnknownNumbers = async () => {
    try {
      const result = await unknownGetAll();
      setUnknownNumber(result);
    } catch (error) {
      console.error('Error fetching unknown numbers:', error);
    }
  };

  useEffect(() => {
    if (!chatGroup.chat_owner) {
      fetchUnknownNumbers();
    }

    phoneNumbersArray.forEach(phoneNumber => {
      getCustomerName(phoneNumber);
    });
  }, [chatGroup]);

  const getUniqueMessages = () => {
    if (!unknownNumber) return unknown;
    const unknownNumbers = unknownNumber.map(item => item.phone_number);
    return unknown.filter(message => !unknownNumbers.includes(message.targetNumber));
  };

  const getCustomerName = async (phoneNumber) => {
    try {
      const response = await customerPhone(phoneNumber);
      if (response && response.data) {
        const name = response.data.fullName;
        setCustomerNames(prevNames => ({ ...prevNames, [phoneNumber]: name }));
      } else if (response===null){
      setCustomerNames(prevNames => ({ ...prevNames, [phoneNumber]: "Não cadastrado" }));
      }
    } catch (error) {
    }
  };

  const viewOption = () => {
    setOptionsVisibility(true);
  };

  const hiddenOption = () => {
    setOptionsVisibility(false);
  };

  const showOptions = () => {
    setOptions(!options);
  };

  const ChatGroupDelete = async () => {
    const returnDelete = await deleteGroup(chatGroup.chat_id);
    if (returnDelete) {
      window.location.reload();
    }
  };

  const ChatGroupEdit = () => {
    setShowGroup(!showGroup);
  };

  return (
    <div className={styles.chatGroupContainer}>
      <div className={styles.chatGroupHeader} onMouseOver={viewOption} onMouseLeave={hiddenOption}>
        {chatGroup.chat_owner !==null && optionsVisibility && (
          <div className={styles.chatoption}>
            {!options && <Settings onClick={showOptions} />}
            {options && <Close onClick={showOptions} />}
          </div>
        )}
        {options && (
          <div className={styles.chatoptioncontainer}>
            <div className={styles.chatoptioncontainerline} onClick={ChatGroupDelete}>Deletar</div>
            <div className={styles.chatoptioncontainerline} onClick={ChatGroupEdit}>Editar</div>
          </div>
        )}
        {chatGroup.chat_name}
      </div>
      <div className={styles.chatGroupBody}>
        { phoneNumbersArray.map((phoneNumber, index) => (
          phoneNumber !=='' &&
          <div key={index} className={styles.chatGroupLine}>
            <div
              onClick={() => toggleCustomer(customerNames[phoneNumber] , phoneNumber)}
              className={styles.chatGroupItem}
            >
              {`${customerNames[phoneNumber] || "Carregando..."} - ${phoneNumber}`}
            </div>
          </div>
          
        ))}
        {!chatGroup.chat_owner && getUniqueMessages().map((index) => (
          <div
            key={index.targetNumber}
            className={styles.chatGroupLine}
            onClick={() => toggleCustomer("unknown", index.targetNumber)}
          >
            <div className={styles.chatGroupItem}>
              {"Unknown"} - {index.targetNumber}
            </div>
          </div>
        ))}
      </div>
      {showGroup && (
        <div><GroupRegister group={chatGroup} toggleGroup={ChatGroupEdit} /></div>
      )}
    </div>
  );
};