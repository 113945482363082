import React, { useState } from 'react';
import ContactItem from './ContactItem';
import './contact.scss';
import Cookies from 'js-cookie';

export const ContactList = ({ contacts, handleDelete, handleEdit }) => {
  const [search, setSearch] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const contactsPerPage = 20;

  const handleSearchChange = (e) => {
    setSearch(e.target.value);
  };

  const filteredContacts = contacts
    // .filter(contact => contact.customer_owner === Cookies.get("idName")) // Filtro por customer_owner
    .filter(contact => {
      const searchLowerCase = search.toLowerCase();
      const {
        fullName,
        birthDate,
        gender,
        maritalStatus,
        nationality,
        cpfCnpj,
        address,
        homePhone,
        cellPhone,
        workPhone,
        email,
        profession,
        company,
        jobTitle,
        communicationPreferences,
        interests,
        monthlyIncome,
        creditLimit,
        purchaseHistory,
        preferredPaymentMethods,
        paymentStatus,
        interactionHistory,
        documents,
        internalNotes,
        references,
        socialMedia,
        relativesBirthdays,
        termsOfUseAgreement,
        communicationConsent,
        dataUsageAuthorization,
        marketSegment,
        classification,
        operatingRegion,
        sourceOfAcquisition,
        marketingCampaigns,
        promotionsUtilized,
        feedbackAndReviews,
        complaintsResolved,
        suggestionsForImprovement,
        customerType,
        alert,
        tag,
        customer_owner
      } = contact;

      const fields = [
        fullName,
        birthDate,
        gender,
        maritalStatus,
        nationality,
        cpfCnpj,
        address,
        homePhone,
        cellPhone,
        workPhone,
        email,
        profession,
        company,
        jobTitle,
        communicationPreferences,
        interests,
        monthlyIncome,
        creditLimit,
        purchaseHistory,
        preferredPaymentMethods,
        paymentStatus,
        interactionHistory,
        documents,
        internalNotes,
        references,
        socialMedia,
        relativesBirthdays,
        termsOfUseAgreement ? 'true' : 'false',
        communicationConsent ? 'true' : 'false',
        dataUsageAuthorization ? 'true' : 'false',
        marketSegment,
        classification,
        operatingRegion,
        sourceOfAcquisition,
        marketingCampaigns,
        promotionsUtilized,
        feedbackAndReviews,
        complaintsResolved,
        suggestionsForImprovement,
        customerType,
        alert,
        tag,
        customer_owner
      ];

      return fields.some(field => String(field).toLowerCase().includes(searchLowerCase));
    });

  const indexOfLastContact = currentPage * contactsPerPage;
  const indexOfFirstContact = indexOfLastContact - contactsPerPage;
  const currentContacts = filteredContacts.slice(indexOfFirstContact, indexOfLastContact);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <div className="contact-list">
      <h1>Lista de Contatos</h1>
      <input
        type="text"
        placeholder="Buscar..."
        value={search}
        onChange={handleSearchChange}
      />
      <table>
        <thead>
          <tr>
            <th>Nome Completo</th>
            <th>Telefone</th>
            <th>Email</th>
            <th>Ações</th>
          </tr>
        </thead>
        <tbody>
          {currentContacts.map(contact => (
            <>
              {
                contact.fullName !== null ? (

                  <ContactItem
                    key={contact.id}
                    contact={contact}
                    handleDelete={handleDelete}
                    handleEdit={handleEdit}
                  />
                ) : <></>
              }
            </>
          ))}
        </tbody>
      </table>
      <div className="pagination">
        {Array.from({ length: Math.ceil(filteredContacts.length / contactsPerPage) }, (_, index) => (
          <button key={index + 1} onClick={() => paginate(index + 1)}>
            {index + 1}
          </button>
        ))}
      </div>
    </div>
  );
};
