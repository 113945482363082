import React from 'react';
import styles from './styles.module.scss';

import down from '../../assets/down.png'
export const InternalChats = ({ toggleChatVisibility }) => {
    return (
        <div className={styles.InternalChats}>
            <div className={styles.openChats} id='chatsbar'>
                <div className={styles.btnbg}>
                    <button 
                        className={styles.openHeader} 
                        onClick={toggleChatVisibility}>
                        <div className={styles.btncontent}>
                        <img src={down} alt="" />
                        <p>Online</p>
                    </div>
                    </button>
                </div>
                <div className={styles.openBody}>
                    Chat interno
                </div>
            </div>
        </div>
    );
};