import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { Close } from '@mui/icons-material';
import 'react-toastify/dist/ReactToastify.css';
import styles from './styles.module.scss';

import Cookies from 'js-cookie';
import CustomInput from '../input';
import CustomButton from '../button';
import { useChatGroupService } from '../../services/chatgroup';
import useCustomerService from '../../services/customers';

const validationSchema = Yup.object({
    chat_name: Yup.string().required('O nome do grupo é obrigatório')
});

export const GroupRegister = ({ toggleGroup, group }) => {
    const [isEdited, setIsEdited] = useState(Boolean(group));
    const [customersList, setCustomersList] = useState([]);
    const userId = Cookies.get("idName");
    const { customers } = useCustomerService({ "Authorization": `Bearer ${Cookies.get("accesstoken")}` });
    const { postGroup, putGroup } = useChatGroupService();

    useEffect(() => {
        const getCustomers = async () => {
            const response = await customers.get();
            const filteredCustomers = response.data.filter(customer => customer.fullName !== null );
    
            const formattedCustomers = filteredCustomers.map(customer => ({
                value: customer.cellPhone,
                label: `${customer.fullName} - ${customer.cellPhone}`,
            }));
    
            setCustomersList(formattedCustomers);
        };
        getCustomers();
    }, [userId]);
    

    const initialValues = isEdited ? {
        chat_owner:userId,
        chat_name: group.chat_name,
        chat_numbers: group.chat_numbers.split(',').map(number => ({
            value: number.trim(),
            label: number.trim(),
        }))
    } : {
        chat_owner:userId,
        chat_name: '',
        chat_numbers: []
    };

    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: async (values) => {
            const groupValues = {
                ...values,
                chat_owner:isEdited ? group.chat_owner : userId,
                chat_numbers: values.chat_numbers.map(option => option.value).join(','), 
            };
            const response = isEdited ? await putGroup(group.chat_id, groupValues) : await postGroup(groupValues);
            if (response) {
                window.location.reload();
            }
        },
    });

    const handleChange = (selected) => {
        formik.setFieldValue('chat_numbers', selected);
    };

    return (
        <div className={styles.registerBackground}>
            <div className={styles.registerContainer}>
                <div className={styles.registerHeader}>
                    <h2 className={styles.titleregister}>{isEdited ? 'Editar Grupo' : 'Novo Grupo'}</h2>
                    <div className={styles.close}>
                        <Close onClick={toggleGroup} />
                    </div>
                </div>
                <form onSubmit={formik.handleSubmit}>
                    <div className={styles.form}>
                        <div className={styles.formInput}>
                            <CustomInput
                                type="text"
                                id="chat_name"
                                label="Digite o nome do grupo"
                                name="chat_name"
                                autoComplete="chat_name"
                                size="small"
                                value={formik.values.chat_name}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={formik.touched.chat_name && formik.errors.chat_name ? formik.errors.chat_name : ''}
                            />
                        </div>
                    </div>
                    <div className={styles.form}>
                        <div className={styles.formInput}>
                            <label htmlFor="multi-select">Selecione os números desse grupo</label>
                            <Select
                                id="multi-select"
                                isMulti
                                options={customersList}
                                value={formik.values.chat_numbers}
                                onChange={handleChange}
                                onBlur={() => formik.setFieldTouched('chat_numbers', true)}
                            />
                            {formik.touched.chat_numbers && formik.errors.chat_numbers ? (
                                <div className={styles.error}>{formik.errors.chat_numbers}</div>
                            ) : null}
                        </div>
                    </div>
                    <div className={styles.submitbtn}>
                        <CustomButton text={isEdited ? 'Salvar Alterações' : 'Cadastrar Grupo'} type="submit" className={styles.extraPadding} />
                    </div>
                </form>
            </div>
        </div>
    );
};
