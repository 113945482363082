// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_customSelect__CC8J7 * {
  border-radius: 8px !important;
  z-index: 2;
  text-align: left;
}

.styles_erroMensagem__yipxC {
  border-color: red;
}`, "",{"version":3,"sources":["webpack://./src/components/select/styles.module.scss"],"names":[],"mappings":"AAAA;EACI,6BAAA;EACA,UAAA;EACA,gBAAA;AACJ;;AAGA;EACI,iBAAA;AAAJ","sourcesContent":[".customSelect * {\n    border-radius: 8px !important;\n    z-index: 2;\n    text-align: left;\n\n    \n}\n.erroMensagem{\n    border-color: red;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"customSelect": `styles_customSelect__CC8J7`,
	"erroMensagem": `styles_erroMensagem__yipxC`
};
export default ___CSS_LOADER_EXPORT___;
