import styles from './styles.module.scss';
import { Description } from '../docDescription';
export const ImageMessageView = ({src, sender, text, type}) =>{
    return(
        <div className={styles.ImageMessageView} data-sender={sender}>
    {type ? (
        <>
            <img src={src} className={styles.img} />
            {text && <Description text={text} />}
        </>
    ) : (
        <>
            <video src={src} className={styles.img} controls />
            {text && <Description text={text} />}
        </>
    )}
</div>


    )
}