// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.excludeButton {
  border-radius: 8px !important;
}`, "",{"version":3,"sources":["webpack://./src/components/excludeButton/styles.modules.scss"],"names":[],"mappings":"AAEA;EACI,6BAAA;AADJ","sourcesContent":["@import '../../styles/colors.scss';\n\n.excludeButton{\n    border-radius: 8px !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
