import styles from './styles.module.scss';
import down from '../../assets/down.svg';
import upb from '../../assets/up.svg';
import { useState } from 'react';

export const Suggestions = ({ onSelectMessage, suggestions }) => {
    const [switchTone, setswitchTone] = useState(false);

    const mudarTom = () => {
        setswitchTone(!switchTone);
    };

    const handleClickMessage = (message) => {
        if (onSelectMessage) {
            onSelectMessage(message);
        }
    };

    return (
        <div className={styles.Suggestions} >

            <div className={styles.options}>
                {switchTone &&
                    <div className={styles.toneOptions}>
                        <div>Profissional</div>
                        <div>Casual</div>
                        <div>Amigável</div>
                    </div>}
                <div className={styles.buttonTone} onClick={mudarTom}>
                    <p>Mudar tom</p>
                    <div className={styles.switchIcon}> 
                        <img src={switchTone ? down : upb} alt="Switch Icon" />
                    </div>
                </div>
            </div>
            <div className={styles.suggestionsMessages}>
                {suggestions && suggestions.map((message)=>(
                    <div className={styles.message} onClick={() => handleClickMessage(message.content)}>{message.content}</div>
                ))} 
                <div className={styles.clear} onClick={() => handleClickMessage('')}>
                    Limpar 
                </div>
            </div>
        </div>
    );
};
