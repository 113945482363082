import Login from '../pages/login';
import { createBrowserRouter } from 'react-router-dom';
import {Home} from '../pages/home';
import { PrivateRoutes } from './auth';
import {ExternalChats} from '../pages/chats';
import {Quotes} from '../pages/quotes';
import {Contacts} from '../pages/contacts';
import MainContainer from '../components/container';
import { Users } from '../pages/user';
import { Lgpd } from '../pages/lgpd';

export const routes = createBrowserRouter([
  {
    element: <PrivateRoutes />,
    children: [
      {
        element: (
          <MainContainer>
            <Home />
          </MainContainer>
        ),
        path: '/home',
      },
      {
        element: (
          <MainContainer>
            <ExternalChats />
          </MainContainer>
        ),
        path: '/chats',
      },
      {
        element: (
          <MainContainer>
            <Quotes />
          </MainContainer>
        ),
        path: '/quotes',
      },
      {
        element: (
          <MainContainer>
            <Contacts />
          </MainContainer>
        ),
        path: '/contacts',
      },
      {
        element: (
          <MainContainer>
            <Users />
          </MainContainer>
        ),
        path: '/user',
      },
    ],
  },
  { element: <Login />, path: '/' },
  { element: <Lgpd />, path: '/lgpd' },
]);