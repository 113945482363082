import { Close } from '@mui/icons-material';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import styles from './styles.module.scss';
import useUserServices from '../../services/user';
import Cookies from 'js-cookie';
import { useState, useEffect } from 'react';
import UserPhoto from '../../assets/do-utilizador.png';
import CustomButton from '../button';
import { CustomExcludeButton } from '../excludeButton';

export const UserView = ({ toggleView, userId, toggleEdit, toggleExclude }) => {
    const { deleteUser, getUser } = useUserServices();
    const [userData, setUserData] = useState([]);
    
    useEffect(() => {
        const handleViewUser = async () => {
            const response = await getUser(userId, {"Authorization":`Bearer ${Cookies.get("accesstoken")}`});
            setUserData(response.data);
        };
        handleViewUser();
    }, [userId]);

    return (
        <div className={styles.registerBackground}>
            <ToastContainer />
            <div className={styles.registerContainer}>
                <div className={styles.registerHeader}>
                    <div className={styles.close}>
                        <Close onClick={toggleView} />
                    </div>
                </div>
                <div className={styles.userData}>
                    <div className={styles.content}>
                        <img src={UserPhoto} alt="" />
                        <div className={styles.nameRole}>
                            <div className={styles.name}>{userData.name}</div>
                            <div className={styles.role}>{userData.role}</div>
                        </div>
                    </div>
                    <div className={styles.emailPhone}>
                        <p><b>Email: </b>{userData.email}</p>
                        <p><b>Telefone: </b>+00 (00)00000-0000</p>
                    </div>
                </div>
                <div className={styles.userFooter}>
                    <CustomButton text="Editar"
                        onClick={() => {
                            toggleEdit(userId);
                        }}
                    >
                        Editar
                    </CustomButton>
                    <CustomExcludeButton text="Excluir"
                        onClick={() => {
                            toggleExclude(userId);
                        }}
                    >
                        Editar
                    </CustomExcludeButton>
                </div>
            </div>
        </div>
    );
};
