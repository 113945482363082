import styles from './styles.module.scss';
import useUserServices from '../../services/user';
import { useEffect, useState } from 'react';
import Cookies from 'js-cookie';
import Close from '@mui/icons-material/Close';
import Button from '../../components/button';
import useCustomerService from '../../services/customers';
import { useChatGroupService } from '../../services/chatgroup';

export const TransferConversation = ({ toggleTransfer, number }) => {
    const { userGetAll } = useUserServices();
    const { customerPhone, customers } = useCustomerService({ "Authorization": `Bearer ${Cookies.get("accesstoken")}` });
    const { getAllGroups, putGroup } = useChatGroupService();

    const [allUsers, setAllUsers] = useState([]);
    const [selectedUser, setSelectedUser] = useState();
    const [selectedCustomer, setSelectedCustomer] = useState();

    useEffect(() => {
        const handleViewUsers = async () => {
            const response = await userGetAll({ "Authorization": `Bearer ${Cookies.get("accesstoken")}` });
            setAllUsers(response.data);
        };
        handleViewUsers();
    }, []);

    const toggleSelectUser = (id) => {
        setSelectedUser(id);
    };

    const getCustomerByPhone = async () => {
        try {
            const response = await customerPhone(number);
            setSelectedCustomer(response.data);
            console.table(response.data);
        } catch {
            alert("Contato não salvo!");
            toggleTransfer();
        }
    };

    useEffect(() => {
        getCustomerByPhone();
    }, []);

    const toggleCustomerOwner = async (id, data) => {
        const response = await customers.put(id, data);
        console.log(response);
    };

    const updateChatGroups = async () => {
        const { data: chatGroups } = await getAllGroups();

        chatGroups.forEach(async (group) => {
            const updatedNumbers = group.chat_numbers
                .split(',')
                .filter(phone => phone !== number)
                .join(',');

            if (updatedNumbers !== group.chat_numbers) {
                await putGroup(group.chat_id, { ...group, chat_numbers: updatedNumbers });
            }
        });
    };

    const Transfer = async () => {
        if (!selectedUser) {
            alert("Selecione um usuário");
        } else {
            if (selectedCustomer) {
                if (selectedCustomer.customer_owner == Cookies.get("idName")) {
                    selectedCustomer.customer_owner = selectedUser;
                    await toggleCustomerOwner(selectedCustomer.id, selectedCustomer);
                    await updateChatGroups();
                    window.location.reload();
                    alert("Conversa transferida!");
                } else {
                    alert("Não é possível transferir a conversa de outro usuário!");
                }
            } else {
                alert("Selected Customer não existe!");
            }
        }
    };

    return (
        <div className={styles.transferContainer}>
            <div className={styles.transferHeader}>
                <p>Selecione um usuário para transferir a conversa</p>
                <div className={styles.close} onClick={toggleTransfer}>
                    <Close />
                </div>
            </div>
            <div className={styles.transferBody}>
                <div className={styles.userList}>
                    {allUsers
                        .map((user) => (
                            <div className={styles.line} data-selected={selectedUser && selectedUser == user.id} onClick={() => toggleSelectUser(user.id)}>{user.name} - {user.email}</div>
                        ))}
                </div>
            </div>
            <div className={styles.transferFooter}>
                <Button text="transferir conversa" onClick={Transfer} />
            </div>
        </div>
    );
}
