import React, { useEffect, useState } from 'react';
import ContactForm from '../../components/contact/ContactForm';
import { ContactList } from '../../components/contact/ContactList';
import useCustomerService from '../../services/customers';
import Cookies from 'js-cookie';
import './contact.scss';
import {
  useQuery
} from '@tanstack/react-query';

export const Contacts = () => {
  const [view, setView] = useState('list');
  const [contacts, setContacts] = useState([]);
  const [currentContact, setCurrentContact] = useState(null);
  const { customers } = useCustomerService({"Authorization": `Bearer ${Cookies.get("accesstoken")}`});

  const { isPending, error, data, isFetching } = useQuery({
    queryKey: ['repoData'],
    queryFn: async () => {
      const response = await customers.get();
      console.log(response.data);
      return response.data;
    },
  });
  // useEffect(() => {
  //   customers.get().then((response) => {
  //     setContacts(response.data);
  //     console.log(response.data);
  //   }).catch((error) => {
  //     console.error(error);
  //   });
  // }, []);

  function handleDelete(contact) {
    customers.delete(contact.id).then((response) => {
      setContacts(contacts.filter((c) => c.id !== contact.id));
      window.alert('Contato excluído com sucesso!')
    }).catch((error) => {
      console.error(error);
    });
  }

  const addContact = (contact) => {
    customers.post(contact).then((response) => {
      const newContact = response.data;
      setContacts([...contacts, newContact]);
      window.alert('Contato cadastrado com sucesso!')
      setView('list');
    }).catch((error) => {
      console.error(error);
    });
  };

  const updateContact = (contact) => {
    customers.put(contact.id, contact).then((response) => {
      const updatedContact = response.data;
      setContacts(contacts.map(c => c.id === updatedContact.id ? updatedContact : c));
      window.alert('Contato atualizado com sucesso!');
      setView('list');
    }).catch((error) => {
      console.error(error);
    });
  };

  if(isPending || isFetching || error) return (<>Carregando Dados</>);

  return (
    <div className="contacts-page">
      {view === 'list' && (
        <>
          <button onClick={() => {
            setCurrentContact(null);
            setView('form');
          }}>
            Cadastrar Novo Contato
          </button>
          <ContactList 
            contacts={data} 
            handleDelete={handleDelete} 
            handleEdit={(contact) => {
              setCurrentContact(contact);
              setView('form');
            }}
          />
        </>
      )}
      {view === 'form' && (
        <>
          <button onClick={() => setView('list')}>Voltar para Lista</button>
          <ContactForm 
            contact={currentContact} 
            handleSave={currentContact ? updateContact : addContact} 
            handleCancel={() => setView('list')}
          />
        </>
      )}
    </div>
  );
};

export default Contacts;
