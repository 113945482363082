// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_body__gLhz8 {
  width: 100%;
  height: 100vh;
}

header {
  background-color: #6892ff;
  display: flex;
  height: 4rem;
}

.styles_logo__xpwwS {
  display: flex;
  align-items: center;
  width: 25%;
}

.styles_logo__xpwwS h1 {
  color: #fff;
}

.styles_icone__sn9G9 {
  padding: 0 1rem 0 1rem;
  height: 2.5rem;
}

.styles_title__3-zWQ {
  display: flex;
  align-items: center;
  color: #fff;
  justify-content: center;
  font-size: 1.4rem;
  width: 50%;
}

.styles_subtitle__B4bKX {
  padding: 1.2rem 4rem;
  font-size: 1.2rem;
  font-weight: bold;
}

.styles_topText__5d5hP {
  padding: 1.2rem 4rem;
  font-size: 1.1rem;
}`, "",{"version":3,"sources":["webpack://./src/pages/lgpd/styles.module.scss","webpack://./src/styles/colors.scss"],"names":[],"mappings":"AAEA;EACI,WAAA;EACA,aAAA;AADJ;;AAIA;EACI,yBCRE;EDSF,aAAA;EACA,YAAA;AADJ;;AAIA;EACI,aAAA;EACA,mBAAA;EACA,UAAA;AADJ;;AAIA;EACI,WAAA;AADJ;;AAIA;EACI,sBAAA;EACA,cAAA;AADJ;;AAIA;EACI,aAAA;EACA,mBAAA;EACA,WAAA;EACA,uBAAA;EACA,iBAAA;EACA,UAAA;AADJ;;AAIA;EACI,oBAAA;EACA,iBAAA;EACA,iBAAA;AADJ;;AAIA;EACI,oBAAA;EACA,iBAAA;AADJ","sourcesContent":["@import '../../styles/colors.scss';\n\n.body{\n    width: 100%;\n    height: 100vh;\n}\n\nheader{\n    background-color: $main;\n    display: flex;\n    height: 4rem;\n}\n\n.logo{\n    display: flex;\n    align-items: center;\n    width: 25%;\n}\n\n.logo h1{\n    color: #fff;\n}\n\n.icone{\n    padding: 0 1rem 0 1rem;\n    height: 2.5rem;\n}\n\n.title{\n    display: flex;\n    align-items: center;\n    color: #fff;\n    justify-content: center;\n    font-size: 1.4rem;\n    width: 50%;\n}\n\n.subtitle{\n    padding: 1.2rem 4rem;\n    font-size: 1.2rem;\n    font-weight: bold;\n}\n\n.topText{\n    padding: 1.2rem 4rem;\n    font-size: 1.1rem;\n}","$main:#6892ff;\n$iacolor:#4CAF50;\n$primary:#ffffff;\n$secondary: #EAEAEA;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"body": `styles_body__gLhz8`,
	"logo": `styles_logo__xpwwS`,
	"icone": `styles_icone__sn9G9`,
	"title": `styles_title__3-zWQ`,
	"subtitle": `styles_subtitle__B4bKX`,
	"topText": `styles_topText__5d5hP`
};
export default ___CSS_LOADER_EXPORT___;
