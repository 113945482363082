import {useRef} from 'react';
import './AudioPlayer.scss';

const AudioPlayer = ({ src, sender }) => {
  const playerRef = useRef(null);

  return (
    <div className="audio-player" data-sender={sender}>
      <audio ref={playerRef} controls>
        <source src={src} type="audio/mpeg" />
        Seu navegador não possui suporte para este tipo de arquivo.
      </audio>
    </div>
  );
};

export default AudioPlayer;
