// ... outras importações
import React, { useEffect, useRef, useState } from 'react';
import { Message } from "../../components/messages";
import styles from './styles.module.scss';
import close from '../../assets/close2.svg';
import options from '../../assets/more.png';
import { SwitchIos } from '../switch';
import down from '../../assets/down.svg';
import upb from '../../assets/up.svg';
import ChatInput from '../chatinput';
import { useChatService } from '../../services/chat';
import { Notes } from '../notes';
import { Suggestions } from '../suggestions';
import { useChat } from '../../hooks/use-chats';
import { observer } from 'mobx-react-lite';
import { chatStore } from "../../stores/chat-store";
import { toast, ToastContainer } from 'react-toastify';
import Cookies from 'js-cookie';
import AudioPlayer from '../audioPlayer';
import { ImageMessageView } from '../imageMessage';
import { FileMessageView } from '../fileMessage';
import useCustomerService from '../../services/customers';
import { CreateCustomerInGroup } from '../createCustomerinGroup';
import { TransferConversation } from '../TransferConversation';
import { GroupRegister } from '../newChatGroup';

export const ChatContainer = observer(({ closeChat, name, number, photo }) => {
    let chatRef = useRef(null);

    const { postMessage, postFile, getAllMessages, activeChatBot, messageSuggestions } = useChatService();
    const [chatBot, setChatbot] = useState();
    const [signature, setSignature] = useState(true);
    const [contacts, setContacts] = useState([]);
    const [message, setMessage] = useState('');
    const [notes, setNotes] = useState('');
    const [showNotes, setShowNotes] = useState(false);
    const [showSuggestions, setShowSuggestions] = useState(false);
    const [suggestions, setSuggestions] = useState([]);
    const [createGroup, setCreateGroup] = useState(false);
    const [transferConversation, setTransferConversation] = useState(false);
    const [chatOptions, setChatOptions] = useState(false);
    const [groupMessage, setGroupMessage] =useState();
    const [createChatGroup, setCreateChatGroup] = useState(false);
    const phone = number;
    const { sendClientMessage } = useChat();
    const nameUser = Cookies.get("userName");
    const { customers } = useCustomerService({"Authorization": `Bearer ${Cookies.get("accesstoken")}`});

    const getChatbotActive = async () => {
        try {
            const response = await activeChatBot.get(`target_phone=${phone}`);
            setChatbot(response.data.is_active);
        } catch (error) {
        }
    };

    const handleSelectedMessage = (message) => {
            setMessage(message);
    };

    useEffect(() => {
        getChatbotActive();
        sendClientMessage();
    }, [phone]);

    useEffect(() => {
        sendClientMessage();
    });

    //Pegando contatos salvos
    useEffect(() => {
        customers.get().then((response) => {
          setContacts(response.data);
        }).catch((error) => {
          console.error(error);
        });
      }, []);

    const viewChatBot = () => {
        const handleActiveChatBot = async () => {
            const dataRequest = {
                'target_phone': phone,
                'is_active': !chatBot
            };
            await activeChatBot.put(dataRequest);
        }
        handleActiveChatBot();
        if (!chatBot) {
            setMessage('');
            if (!toast.isActive("chatBotToast")) {
                toast.info("Chatbot ativo", { toastId: "chatBotToast" });
            }
        } else {
            if (!toast.isActive("chatBotDisabledToast")) {
                toast.info("Chatbot desativado", { toastId: "chatBotDisabledToast" });
            }
        }
    }

    const enviarMensagem = async (messageObject) => {
        let dataRequest;
        try {
            if (messageObject.content) {
                if (chatRef.current) {
                    chatRef.current.scrollTop = chatRef.current.scrollHeight;
                }
                if(messageObject.type=="audio"){
                    
                    const fileReturn = await postFile(`${messageObject.content}`, "audio");
                    dataRequest = {
                        message: {
                            target_phone: phone,
                            text: "",
                            from_bot: false,
                            is_assigned: false,
                            media_url: fileReturn.media_url
                        }
                    };
                } else if(messageObject.type=="image" || messageObject.type=="file"){
                    const fileReturn = await postFile(`${messageObject.content}`, messageObject.type);
                    dataRequest = {
                        message: {
                            target_phone: phone,
                            text: signature ? `*${nameUser}*\n${messageObject.text}` : messageObject.text,
                            from_bot: false,
                            is_assigned: false,
                            media_url: fileReturn.media_url
                        }
                    };
                }else if(messageObject.type=="text"){
                    dataRequest = {
                        message: {
                            target_phone: phone,
                            text: signature ? `*${nameUser}*\n${messageObject.content}` : messageObject.content,
                            from_bot: false,
                            is_assigned: false
                        
                        }
                    };
                } else{
                    const fileReturn = await postFile(`${messageObject.content}`);
                    dataRequest = {
                        message: {
                            target_phone: phone,
                            text: signature ? `*${nameUser}*\n${messageObject.text}` : messageObject.text,
                            from_bot: false,
                            is_assigned: false,
                            media_url: fileReturn.media_url
                        }
                    };
                }

                setMessage('');
                const foundContact = contacts.find(contact => contact.cellPhone === phone);
                if(foundContact){
                    if(foundContact.customer_owner === Cookies.get("idName")){
                        await postMessage(dataRequest.message, { "Authorization": `Bearer ${Cookies.get("accesstoken")}` });
                    } else{
                        toast.error("Esse contato já está sendo atendido!");
                    }
                } else{
                    setGroupMessage(dataRequest.message);
                    setCreateGroup(true);
                    
                }
            }
        } catch (error) {
            toast.error('Failed to send message');
        }
    };

    
    useEffect(() => {
        const handleViewMessages = async () => {
            const messageList = await getAllMessages(phone);
            chatStore.setViewMessages(messageList);
        };
        handleViewMessages();
    }, []);

    useEffect(() => {
        const handleViewMessages = async () => {
            const messageList = await getAllMessages(phone);
            chatStore.setViewMessages(messageList);
        };
        handleViewMessages();
    }, [phone]);

    useEffect(() => {
        chatRef.current.scrollTop = chatRef.current.scrollHeight;
    });

    const toggleNotes = () => {
        setShowNotes(!showNotes);
        if (showSuggestions) {
            setShowSuggestions(false);
        }
    };

    const toggleOptions = () =>{
        if(!transferConversation){
        setChatOptions(!chatOptions);
        }
    }

    const toggleTransfer = () =>{
        setTransferConversation(!transferConversation);
        setChatOptions(false);
    }

    const toggleSuggestions = () => {
        setShowSuggestions(!showSuggestions);
        if (showNotes) {
            setShowNotes(false);
        }
    };

    const toggleCreateChatGroup = () =>{
        setCreateChatGroup(!createChatGroup);
    }

    const handleNoteSubmit = async (noteContent) => {
        if (noteContent) {
            if (chatRef.current) {
                chatRef.current.scrollTop = chatRef.current.scrollHeight;
            }

            const dataRequest = {
                message: {
                    target_phone: phone,
                    text: noteContent,
                    is_note: true
                }
            };

            sendClientMessage(JSON.stringify(dataRequest));
            await postMessage(dataRequest.message);
        }
    };

    useEffect(() => {
        const handleFetchSuggestions = async () => {
            const response = await messageSuggestions({ 'contact': phone });
            setSuggestions(response);

        }

        handleFetchSuggestions();
    }, [chatStore.viewMessages]);

    return (
        <div className={styles.chatarea}>
            {createGroup && 
            <CreateCustomerInGroup number={phone} groupVisibility={setCreateGroup} send={groupMessage} createNewGroup={toggleCreateChatGroup}/>}
            {createChatGroup && <GroupRegister toggleGroup={toggleCreateChatGroup}/>}
            <div className={styles.chatcontainer}>
                <div className={styles.chatheader}>
                    <div className={styles.esquerda}>
                        <img src={photo} className={styles.profile} />
                        <p className={styles.name}>{name}</p>
                        <p className={styles.number}>{number}</p>
                    </div>
                    <div className={styles.direita}>
                        <button>
                            <img className={styles.optbtn} onClick={toggleOptions} src={options} alt="" />
                        </button>
                        {chatOptions &&
                        <div className={styles.transferConversation}  onClick={toggleTransfer}>Transferir conversa</div>
                        }
                        {transferConversation &&
                        <TransferConversation toggleTransfer={toggleTransfer} number={phone}/>
                        }
                        <button>
                            <img src={close} alt="" onClick={closeChat} />
                        </button>
                    </div>
                </div>
                <div className={styles.chat} data-notes={showNotes} data-suggestions={showSuggestions} ref={chatRef}>
                    {chatStore.viewMessages.length > 0 ? (
                        chatStore.viewMessages.map((message) => (
                            message.mediaType === "document" || message.mediaType === "other" ? (
                                <div data-sender={message.role} className={styles.chatline} assigned={message.is_assigned} key={message.id}>
                                    <FileMessageView text={message.text} file={message.mediaUrl} sender={message.role} />
                                </div>
                            ) : message.mediaType === "audio" ? (
                                <div data-sender={message.role} className={styles.chatline} assigned={false} key={message.id}>
                                    <AudioPlayer src={message.mediaUrl} sender={message.role} />
                                </div>
                            ) : message.mediaType === "image" ? (
                                <div data-sender={message.role} className={styles.chatline} assigned={false} key={message.id}>
                                    <ImageMessageView text={message.text} type="true" src={message.mediaUrl} sender={message.role} />
                                </div>
                            ) : message.mediaType === "video" ? (
                                <div data-sender={message.role} className={styles.chatline} assigned={false} key={message.id}>
                                    <ImageMessageView text={message.text} type={false} src={message.mediaUrl} sender={message.role} />
                                </div>
                            ) : (
                                <div data-sender={message.role} className={styles.chatline} assigned={message.is_assigned} key={message.id}>
                                    <Message text={message.text} hour={"00:15"} sender={message.role} />
                                </div>
                            )
                        ))
                    ) : (
                        <div>Não há nenhuma mensagem</div>
                    )}

                    <div className={styles.space}></div>
                </div>
                <div className={styles.footercontainer}>
                    <div className={styles.chatfooter}>
                        {showNotes && <div className={styles.inputNotes}><Notes notes={notes} setNotes={setNotes} onNoteSubmit={handleNoteSubmit} /></div>}
                        {showSuggestions && <div className={styles.inputSuggestions}><Suggestions suggestions={suggestions} onSelectMessage={handleSelectedMessage} /></div>}
                        <div className={styles.funcionalidades}>
                            <div className={styles.funcao}>
                                <p>Assinar</p>
                                <div className={styles.switch}>
                                    <SwitchIos chatbotactive={signature} set={setSignature} />
                                </div>
                            </div>
                            <div className={styles.funcao}>
                                <p>Chatbot</p>
                                <div className={styles.switch} onClick={viewChatBot}>
                                    <SwitchIos chatbotactive={chatBot} set={setChatbot} />
                                </div>
                            </div>
                            <div className={styles.sugestoes} onClick={toggleSuggestions}>
                                <p>Sugestões de mensagens</p>
                                <img src={showSuggestions ? down : upb} />
                            </div>
                            <div className={styles.notes} onClick={toggleNotes}>
                                <p>Anotações</p>
                                <img src={showNotes ? down : upb} />
                            </div>
                            <ToastContainer />
                        </div>
                        <form onSubmit={(e) => {
                            e.preventDefault();
                            enviarMensagem({ type: 'text', content: message });
                        }}>
                            <div className={styles.inputmessage}>
                                <ChatInput message={message} setMessage={setMessage} chatBot={chatBot} onSendMessage={enviarMensagem} />
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
});
