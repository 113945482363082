
import style from './LoginStyle.scss'
import logo from '../../assets/logo.png'
import ResetPassword from '../../components/ResetPassword'
import React, { useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { LoginForm } from '../../components/LoginForm';

function Login() {
    const [showResetPassword, setShowResetPassword] = useState(false)
    const [email, setEmail] = useState('')

    function validarEmail(email) {
      const re = /\S+@\S+\.\S+/;
      return re.test(email);
  }

  const handlePasswordResetClick = () => {
    if (!email) {
      toast.error("Informe o seu email!")
    }
    else if(!(validarEmail(email))){
      toast.error("Informe um email válido!")
    } else {
      setShowResetPassword(true)
    }
  };
  return (
    
    <div className='container'>
      <div className='item'>
        <div className="section">
          <img src={logo} className='logoIcon' />
          <p className='title'>MaximizeAi</p>
        </div>
      </div>

      <div className='item m-0'>
      <div>
      {showResetPassword ? (
          <ResetPassword />
        ) : (
          <LoginForm
            onForgotPasswordClick={handlePasswordResetClick} 
            setEmail={setEmail} email={email}
          />
        )}
    </div>
      </div>
      
      <ToastContainer />
    </div>
    
  )
}

export default Login;