// ExternalChats.js
import { ChatContainer } from "../../components/chatcontainer";
import styles from './styles.module.scss';
import profile from '../../assets/profile.png';
import { useEffect, useState } from "react";
import IconChat from '../../assets/graychat.png';
import { chatStore } from "../../stores/chat-store";
import { useUnknownChat } from "../../hooks/use-chat-unknown";
import { ChatContainerGroup } from "../../components/chatGroupContainer";
import { GroupRegister } from "../../components/newChatGroup";

export const ExternalChats = () => {
    const {receiveMessage} =useUnknownChat();
    
    const [selectedChat, setSelectedChat] = useState(null);

    const [showGroup, setShowGroup] = useState(false);

    const {messages} =useUnknownChat(selectedChat);

    useEffect(()=>{
        chatStore.setUnknownMessages(messages);
        console.log("chatstore", chatStore.unknownMessages)
    })


    const toggleCustomer = (customerName, phone) => {
            setSelectedChat({customerName, phone });
        chatStore.setPhoneNumber(phone);
    };

    useEffect(()=>{
        receiveMessage();
    },[])
    
    const closeChat =()=>{
        setSelectedChat(false);
    }

    const toggleGroup = () => {
        setShowGroup(!showGroup);
    }

    return (
        <div className={styles.chatbg}>
            <div className={styles.chatgroup}>
                <ChatContainerGroup toggleGroup={toggleGroup} unknown={messages} toggleCustomer={toggleCustomer}/>
            </div>
            {selectedChat && (
                <ChatContainer 
                    closeChat={closeChat}
                    number={selectedChat.phone} 
                    name= { selectedChat.customerName ? selectedChat.customerName : 'chat'} 
                    photo={profile} 
                />
            )}
            {!selectedChat &&(
                <div className={styles.noChatContainer}>
                    <div className={styles.iconText}>
                    <img src={IconChat} alt="" className={styles.iconChat} />
                    <p>Selecione um chat para iniciar uma conversa</p>
                    </div>
                </div>
            )}
            {showGroup && (
                <GroupRegister toggleGroup={toggleGroup}/>
            )}
        </div>
    );
};
