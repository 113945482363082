// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_FileMessageView__DfyjT {
  width: 20rem;
  background-color: #ffffff;
  border-radius: 5px;
  padding: 1rem;
  display: flex;
  align-items: center;
  color: black;
}
.styles_FileMessageView__DfyjT[data-sender=user] {
  background-color: #6892ff;
  color: #ffffff;
}
.styles_FileMessageView__DfyjT[data-sender=user] .styles_FileInfo__1pHHW {
  filter: invert(1);
}
.styles_FileMessageView__DfyjT[data-sender=user] .styles_FileMessageLink__9qUy- {
  background-color: #3f75ff;
}

.styles_FileMessageLink__9qUy- {
  padding: 0.5rem;
  background-color: #e0e0e0;
  display: flex;
  align-items: center;
  padding-left: 1rem;
}

.styles_FileInfo__1pHHW {
  cursor: pointer;
  border-radius: 5px;
  margin-left: 1rem;
  color: black;
}

.styles_FileName__KwYum {
  display: block;
  font-weight: bold;
}

.styles_FileDetails__h5gBb {
  display: block;
  color: gray;
}

.styles_FileContainer__fZ9tj {
  width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/components/fileMessage/styles.module.scss","webpack://./src/styles/colors.scss"],"names":[],"mappings":"AAGA;EACI,YAAA;EACA,yBCHK;EDIL,kBAAA;EACA,aAAA;EACA,aAAA;EACA,mBAAA;EACA,YAAA;AAFJ;AAII;EACI,yBCbF;EDcE,cCZC;ADUT;AAGQ;EACI,iBAAA;AADZ;AAGQ;EACA,yBAlBI;AAiBZ;;AAOA;EACI,eAAA;EACA,yBAzBW;EA0BX,aAAA;EACA,mBAAA;EACA,kBAAA;AAJJ;;AAOA;EACI,eAAA;EACA,kBAAA;EACA,iBAAA;EACA,YAAA;AAJJ;;AASA;EACI,cAAA;EACA,iBAAA;AANJ;;AASA;EACI,cAAA;EACA,WAAA;AANJ;;AASA;EACI,WAAA;AANJ","sourcesContent":["@import '../../styles/colors.scss';\n$main-dark: darken($main, 8%);\n$primary-dark: darken($primary, 12%);\n.FileMessageView {\n    width: 20rem;\n    background-color: $primary;\n    border-radius: 5px;\n    padding: 1rem;\n    display: flex;\n    align-items: center;\n    color: black;\n\n    &[data-sender=\"user\"]{\n        background-color: $main;\n        color: $primary;\n        .FileInfo{\n            filter: invert(1);\n        }\n        .FileMessageLink{  \n        background-color: $main-dark;\n        }\n    }\n\n}\n\n.FileMessageLink{\n    padding: 0.5rem;\n    background-color: $primary-dark;\n    display: flex;\n    align-items: center;\n    padding-left: 1rem;\n}\n\n.FileInfo {\n    cursor: pointer;\n    border-radius: 5px;\n    margin-left: 1rem;\n    color: black;\n}\n\n\n\n.FileName {\n    display: block;\n    font-weight: bold;\n}\n\n.FileDetails {\n    display: block;\n    color: gray;\n}\n\n.FileContainer{\n    width: 100%;\n}\n","$main:#6892ff;\n$iacolor:#4CAF50;\n$primary:#ffffff;\n$secondary: #EAEAEA;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"FileMessageView": `styles_FileMessageView__DfyjT`,
	"FileInfo": `styles_FileInfo__1pHHW`,
	"FileMessageLink": `styles_FileMessageLink__9qUy-`,
	"FileName": `styles_FileName__KwYum`,
	"FileDetails": `styles_FileDetails__h5gBb`,
	"FileContainer": `styles_FileContainer__fZ9tj`
};
export default ___CSS_LOADER_EXPORT___;
