// Ensure to import necessary modules
import useAxios from "./api";
import Cookies from 'js-cookie';

// Function to get the authorization header

// Custom hook to use unknown services
export const useUnknownServices = (header) => {
    const { api } = useAxios();

    const unknownGetAll = async () => {
        try {
            const response = await api.get('/api/tempcustomers', { headers:header });
            return response.data;
        } catch (error) {
            console.error("Error fetching data:", error);
            throw error;
        }
    };

    const tempCostumerDeleteByPhone = async (target_phone) =>{
        try {
            const response = await api.delete(`/api/tempcustomers/deletebyphone/${target_phone}`, { headers:header });
            return response.data;
        } catch (error) {
            console.error("Error: ", error);
        }
    }

    return { unknownGetAll, tempCostumerDeleteByPhone };
};