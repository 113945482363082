// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_chatContainerGroup__Tzg8- {
  width: 100%;
  height: 100%;
  overflow-y: auto;
}

.styles_newGroup__okyGX {
  color: white;
  background-color: #6892ff;
  width: 80%;
  margin-left: 10%;
  display: flex;
  justify-content: center;
  font-size: 1.5rem;
  border-radius: 8px;
  cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/components/chatGroupContainer/styles.module.scss","webpack://./src/styles/colors.scss"],"names":[],"mappings":"AACA;EACI,WAAA;EACA,YAAA;EACA,gBAAA;AAAJ;;AAGA;EACI,YAAA;EACA,yBCTE;EDUF,UAAA;EACA,gBAAA;EACA,aAAA;EACA,uBAAA;EACA,iBAAA;EACA,kBAAA;EACA,eAAA;AAAJ","sourcesContent":["@import '../../styles/colors.scss';\n.chatContainerGroup {\n    width: 100%;\n    height: 100%;\n    overflow-y: auto;\n}\n\n.newGroup {\n    color: white;\n    background-color: $main;\n    width: 80%;\n    margin-left: 10%;\n    display: flex;\n    justify-content: center;\n    font-size: 1.5rem;\n    border-radius: 8px;\n    cursor: pointer;\n}\n","$main:#6892ff;\n$iacolor:#4CAF50;\n$primary:#ffffff;\n$secondary: #EAEAEA;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"chatContainerGroup": `styles_chatContainerGroup__Tzg8-`,
	"newGroup": `styles_newGroup__okyGX`
};
export default ___CSS_LOADER_EXPORT___;
