// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_ImageMessageView__XTu6D {
  height: auto;
  width: 27rem;
  padding: 1rem;
  border-radius: 5px;
  background-color: #ffffff;
}
.styles_ImageMessageView__XTu6D[data-sender=user] {
  background-color: #6892ff;
  color: #ffffff;
}
.styles_ImageMessageView__XTu6D .styles_img__1LlQ2 {
  width: 27rem;
}`, "",{"version":3,"sources":["webpack://./src/components/imageMessage/styles.module.scss","webpack://./src/styles/colors.scss"],"names":[],"mappings":"AACA;EACA,YAAA;EACA,YAAA;EACA,aAAA;EACA,kBAAA;EACA,yBCJS;ADIT;AAEA;EACI,yBCTE;EDUF,cCRK;ADQT;AAGA;EACA,YAAA;AADA","sourcesContent":["@import '../../styles/colors.scss';\n.ImageMessageView{\nheight: auto;\nwidth: 27rem;\npadding: 1rem;\nborder-radius: 5px;\nbackground-color: $primary;\n\n&[data-sender=\"user\"]{\n    background-color: $main;\n    color: $primary;\n}\n\n.img{\nwidth: 27rem;\n}\n}\n\n\n","$main:#6892ff;\n$iacolor:#4CAF50;\n$primary:#ffffff;\n$secondary: #EAEAEA;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ImageMessageView": `styles_ImageMessageView__XTu6D`,
	"img": `styles_img__1LlQ2`
};
export default ___CSS_LOADER_EXPORT___;
