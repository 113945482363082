import React, { useState, useEffect } from 'react';
import './contactform.scss'; // Certifique-se de que o arquivo CSS está importado
import {useUnknownServices} from '../../services/unknown';
import Cookies from 'js-cookie';

const ContactForm = ({ contact, handleSave, handleCancel }) => {
  const {tempCostumerDeleteByPhone} = useUnknownServices({ "Authorization": `Bearer ${Cookies.get("accesstoken")}` });

  const [formState, setFormState] = useState({
    id: contact ? contact.id : null,
    fullName: '',
    birthDate: '',
    gender: '',
    maritalStatus: '',
    nationality: '',
    cpfCnpj: '',
    address: '',
    homePhone: '',
    cellPhone: '',
    workPhone: '',
    email: '',
    profession: '',
    company: '',
    jobTitle: '',
    communicationPreferences: '',
    interests: '',
    monthlyIncome: '',
    creditLimit: '',
    purchaseHistory: '',
    preferredPaymentMethods: '',
    paymentStatus: '',
    interactionHistory: '',
    documents: '',
    internalNotes: '',
    references: '',
    socialMedia: '',
    relativesBirthdays: '',
    termsOfUseAgreement: false,
    communicationConsent: false,
    dataUsageAuthorization: false,
    marketSegment: '',
    classification: '',
    operatingRegion: '',
    sourceOfAcquisition: '',
    marketingCampaigns: '',
    promotionsUtilized: '',
    feedbackAndReviews: '',
    complaintsResolved: '',
    suggestionsForImprovement: '',
    customerType: '',
    alert: '',
    tag: '',
    customer_owner: Cookies.get("idName")
  });

  useEffect(() => {
    if (contact) {
      setFormState({
        id: contact ? contact.id : null,
        fullName: contact.fullName || '',
        birthDate: contact.birthDate || '',
        gender: contact.gender || '',
        maritalStatus: contact.maritalStatus || '',
        nationality: contact.nationality || '',
        cpfCnpj: contact.cpfCnpj || '',
        address: contact.address || '',
        homePhone: contact.homePhone || '',
        cellPhone: contact.cellPhone || '',
        workPhone: contact.workPhone || '',
        email: contact.email || '',
        profession: contact.profession || '',
        company: contact.company || '',
        jobTitle: contact.jobTitle || '',
        communicationPreferences: contact.communicationPreferences || '',
        interests: contact.interests || '',
        monthlyIncome: contact.monthlyIncome || '',
        creditLimit: contact.creditLimit || '',
        purchaseHistory: contact.purchaseHistory || '',
        preferredPaymentMethods: contact.preferredPaymentMethods || '',
        paymentStatus: contact.paymentStatus || '',
        interactionHistory: contact.interactionHistory || '',
        documents: contact.documents || '',
        internalNotes: contact.internalNotes || '',
        references: contact.references || '',
        socialMedia: contact.socialMedia || '',
        relativesBirthdays: contact.relativesBirthdays || '',
        termsOfUseAgreement: contact.termsOfUseAgreement || false,
        communicationConsent: contact.communicationConsent || false,
        dataUsageAuthorization: contact.dataUsageAuthorization || false,
        marketSegment: contact.marketSegment || '',
        classification: contact.classification || '',
        operatingRegion: contact.operatingRegion || '',
        sourceOfAcquisition: contact.sourceOfAcquisition || '',
        marketingCampaigns: contact.marketingCampaigns || '',
        promotionsUtilized: contact.promotionsUtilized || '',
        feedbackAndReviews: contact.feedbackAndReviews || '',
        complaintsResolved: contact.complaintsResolved || '',
        suggestionsForImprovement: contact.suggestionsForImprovement || '',
        customerType: contact.customerType || '',
        alert: contact.alert || '',
        tag: contact.tag || '',
        customer_owner: Cookies.get("idName")
      });
    }
  }, [contact]);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormState(prevState => ({
      ...prevState,
      [name]: type === 'checkbox' ? checked : value
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    
    // Chama a função tempCostumerDeleteByPhone com o número de celular
    tempCostumerDeleteByPhone(formState.cellPhone);
    
    // Chama a função handleSave passando os dados do formulário
    handleSave(formState);
};

  return (
    <form onSubmit={handleSubmit} className="contact-form">
      <h2>{contact ? 'Editar Contato' : 'Criar Contato'}</h2>
      <div className="form-group">
        <label>Nome Completo:
          <input
            type="text"
            name="fullName"
            placeholder="Nome Completo"
            value={formState.fullName}
            onChange={handleChange}
          />
        </label>
      </div>
      <div className="form-group">
        <label>Data de Nascimento:
          <input
            type="date"
            name="birthDate"
            placeholder="Data de Nascimento"
            value={formState.birthDate}
            onChange={handleChange}
          />
        </label>
      </div>
      <div className="form-group">
        <label>Gênero:
          <select name="gender" value={formState.gender} onChange={handleChange}>
            <option value="">Selecione</option>
            <option value="M">Masculino</option>
            <option value="F">Feminino</option>
            <option value="NB">Não-Binário</option>
            <option value="O">Outro</option>
          </select>
        </label>
      </div>
      <div className="form-group">
        <label>Estado Civil:
          <select name="maritalStatus" value={formState.maritalStatus} onChange={handleChange}>
            <option value="">Selecione</option>
            <option value="SINGLE">Solteiro(a)</option>
            <option value="MARRIED">Casado(a)</option>
            <option value="DIVORCED">Divorciado(a)</option>
            <option value="WIDOWED">Viúvo(a)</option>
            <option value="CIVIL_UNION">União Estável</option>
            <option value="SEPARATED">Separado(a)</option>
          </select>
        </label>
      </div>
      <div className="form-group">
        <label>Nacionalidade:
          <input
            type="text"
            name="nationality"
            placeholder="Nacionalidade"
            value={formState.nationality}
            onChange={handleChange}
          />
        </label>
      </div>
      <div className="form-group">
        <label>CPF/CNPJ:
          <input
            type="text"
            name="cpfCnpj"
            placeholder="CPF/CNPJ"
            value={formState.cpfCnpj}
            onChange={handleChange}
          />
        </label>
      </div>
      <div className="form-group">
        <label>Endereço:
          <input
            type="text"
            name="address"
            placeholder="Endereço"
            value={formState.address}
            onChange={handleChange}
          />
        </label>
      </div>
      <div className="form-group">
        <label>Telefone Residencial:
          <input
            type="tel"
            name="homePhone"
            placeholder="Telefone Residencial"
            value={formState.homePhone}
            onChange={handleChange}
          />
        </label>
      </div>
      <div className="form-group">
        <label>Telefone Celular:
          <input
            type="tel"
            name="cellPhone"
            placeholder="Telefone Celular"
            value={formState.cellPhone}
            onChange={handleChange}
          />
        </label>
      </div>
      <div className="form-group">
        <label>Telefone de Trabalho:
          <input
            type="tel"
            name="workPhone"
            placeholder="Telefone de Trabalho"
            value={formState.workPhone}
            onChange={handleChange}
          />
        </label>
      </div>
      <div className="form-group">
        <label>Email:
          <input
            type="email"
            name="email"
            placeholder="Email"
            value={formState.email}
            onChange={handleChange}
          />
        </label>
      </div>
      <div className="form-group">
        <label>Profissão:
          <input
            type="text"
            name="profession"
            placeholder="Profissão"
            value={formState.profession}
            onChange={handleChange}
          />
        </label>
      </div>
      <div className="form-group">
        <label>Empresa:
          <input
            type="text"
            name="company"
            placeholder="Empresa"
            value={formState.company}
            onChange={handleChange}
          />
        </label>
      </div>
      <div className="form-group">
        <label>Cargo:
          <input
            type="text"
            name="jobTitle"
            placeholder="Cargo"
            value={formState.jobTitle}
            onChange={handleChange}
          />
        </label>
      </div>
      <div className="form-group">
        <label>Preferências de Comunicação:
          <select name="communicationPreferences" value={formState.communicationPreferences} onChange={handleChange}>
            <option value="">Selecione</option>
            <option value="EMAIL">Email</option>
            <option value="PHONE">Telefone</option>
            <option value="SMS">SMS</option>
            <option value="WHATSAPP">WhatsApp</option>
          </select>
        </label>
      </div>
      <div className="form-group">
        <label>Interesses:
        <select name="interests" value={formState.interests} onChange={handleChange}>
            <option value="">Selecione</option>
            <option value="LIFE_INSURANCE">Seguro de Vida</option>
            <option value="AUTO_INSURANCE">Seguro Automotivo</option>
            <option value="HOME_INSURANCE">Seguro Residencial</option>
            <option value="HEALTH_PLAN">Plano de Saúde</option>
            <option value="INVESTMENTS">Investimentos</option>
            <option value="OTHER">Outro</option>
          </select>
        </label>
      </div>
      <div className="form-group">
        <label>Renda Mensal:
          <input
            type="number"
            name="monthlyIncome"
            placeholder="Renda Mensal"
            value={formState.monthlyIncome}
            onChange={handleChange}
          />
        </label>
      </div>
      <div className="form-group">
        <label>Limite de Crédito:
          <input
            type="number"
            name="creditLimit"
            placeholder="Limite de Crédito"
            value={formState.creditLimit}
            onChange={handleChange}
          />
        </label>
      </div>
      <div className="form-group">
        <label>Histórico de Compras:
          <textarea
            name="purchaseHistory"
            placeholder="Histórico de Compras"
            value={formState.purchaseHistory}
            onChange={handleChange}
          />
        </label>
      </div>
      <div className="form-group">
        <label>Métodos de Pagamento Preferidos:
        <select name="preferredPaymentMethods" value={formState.preferredPaymentMethods} onChange={handleChange}>
            <option value="">Selecione</option>
            <option value="CREDIT_CARD">Cartão de Crédito</option>
            <option value="DEBIT_CARD">Cartão de Débito</option>
            <option value="BOLETO">Boleto</option>
            <option value="BANK_TRANSFER">Transferência Bancária</option>
            <option value="CASH">Dinheiro</option>
          </select>
        </label>
      </div>
      <div className="form-group">
        <label>Status de Pagamento:
          <select name="paymentStatus" value={formState.paymentStatus} onChange={handleChange}>
          <option value="COMPLIANT">Adimplente</option>
          <option value="DELINQUENT">Inadimplente</option>
          </select>
        </label>
      </div>
      <div className="form-group">
        <label>Histórico de Interações:
          <textarea
            name="interactionHistory"
            placeholder="Histórico de Interações"
            value={formState.interactionHistory}
            onChange={handleChange}
          />
        </label>
      </div>
      <div className="form-group">
        <label>Documentos:
          <textarea
            name="documents"
            placeholder="Documentos"
            value={formState.documents}
            onChange={handleChange}
          />
        </label>
      </div>
      <div className="form-group">
        <label>Notas Internas:
          <textarea
            name="internalNotes"
            placeholder="Notas Internas"
            value={formState.internalNotes}
            onChange={handleChange}
          />
        </label>
      </div>
      <div className="form-group">
        <label>Referências:
          <textarea
            name="references"
            placeholder="Referências"
            value={formState.references}
            onChange={handleChange}
          />
        </label>
      </div>
      <div className="form-group">
        <label>Redes Sociais:
          <textarea
            name="socialMedia"
            placeholder="Redes Sociais"
            value={formState.socialMedia}
            onChange={handleChange}
          />
        </label>
      </div>
      <div className="form-group">
        <label>Aniversários de Parentes:
          <textarea
            name="relativesBirthdays"
            placeholder="Aniversários de Parentes"
            value={formState.relativesBirthdays}
            onChange={handleChange}
          />
        </label>
      </div>
      <div className="form-group checkbox-group">
        <label>
          <input
            type="checkbox"
            name="termsOfUseAgreement"
            checked={formState.termsOfUseAgreement}
            onChange={handleChange}
          />
          Acordo de Termos de Uso
        </label>
      </div>
      <div className="form-group checkbox-group">
        <label>
          <input
            type="checkbox"
            name="communicationConsent"
            checked={formState.communicationConsent}
            onChange={handleChange}
          />
          Consentimento de Comunicação
        </label>
      </div>
      <div className="form-group checkbox-group">
        <label>
          <input
            type="checkbox"
            name="dataUsageAuthorization"
            checked={formState.dataUsageAuthorization}
            onChange={handleChange}
          />
          Autorização de Uso de Dados
        </label>
      </div>
      <div className="form-group">
        <label>Segmento de Mercado:
        <select name="marketSegment" value={formState.marketSegment} onChange={handleChange}>
            <option value="">Selecione</option>
            <option value="RETAIL">Varejo</option>
            <option value="SERVICES">Serviços</option>
            <option value="CORPORATE">Corporativo</option>
            <option value="INDUSTRIAL">Industrial</option>
            <option value="OTHER">Outro</option>
          </select>
        </label>
      </div>
      <div className="form-group">
        <label>Classificação:
          <select name="classification" value={formState.classification} onChange={handleChange}>
            <option value="VIP">VIP</option>
            <option value="REGULAR">Regular</option>
            <option value="PROSPECT">Prospect</option>
            <option value="INACTIVE">Inativo</option>
            <option value="NEW">Novo</option>
          </select>
        </label>
      </div>
      <div className="form-group">
        <label>Região de Operação:
          <input
            type="text"
            name="operatingRegion"
            placeholder="Região de Operação"
            value={formState.operatingRegion}
            onChange={handleChange}
          />
        </label>
      </div>
      <div className="form-group">
        <label>Fonte de Aquisição:
        <select name="sourceOfAcquisition" value={formState.sourceOfAcquisition} onChange={handleChange}>
            <option value="">Selecione</option>
            <option value="GOOGLE">Google</option>
            <option value="REFERRAL">Indicação de Cliente</option>
            <option value="FACEBOOK_AD">Anúncio no Facebook</option>
            <option value="INSTAGRAM_AD">Anúncio no Instagram</option>
            <option value="EVENTS">Feiras e Eventos</option>
            <option value="OTHER">Outro</option>
          </select>
        </label>
      </div>
      <div className="form-group">
        <label>Campanhas de Marketing:
          <textarea
            name="marketingCampaigns"
            placeholder="Campanhas de Marketing"
            value={formState.marketingCampaigns}
            onChange={handleChange}
          />
        </label>
      </div>
      <div className="form-group">
        <label>Promoções Utilizadas:
          <textarea
            name="promotionsUtilized"
            placeholder="Promoções Utilizadas"
            value={formState.promotionsUtilized}
            onChange={handleChange}
          />
        </label>
      </div>
      <div className="form-group">
        <label>Feedback e Avaliações:
          <textarea
            name="feedbackAndReviews"
            placeholder="Feedback e Avaliações"
            value={formState.feedbackAndReviews}
            onChange={handleChange}
          />
        </label>
      </div>
      <div className="form-group">
        <label>Reclamações Resolvidas:
          <textarea
            name="complaintsResolved"
            placeholder="Reclamações Resolvidas"
            value={formState.complaintsResolved}
            onChange={handleChange}
          />
        </label>
      </div>
      <div className="form-group">
        <label>Sugestões de Melhoria:
          <textarea
            name="suggestionsForImprovement"
            placeholder="Sugestões de Melhoria"
            value={formState.suggestionsForImprovement}
            onChange={handleChange}
          />
        </label>
      </div>
      <div className="form-group">
        <label>Tipo de Cliente:
        <select name="customerType" value={formState.customerType} onChange={handleChange}>
            <option value="">Selecione</option>
            <option value="INDIVIDUAL">Individual</option>
            <option value="COMPANY">Empresa</option>
          </select>
        </label>
      </div>
      <div className="form-group">
        <label>Alerta:
          <input
            type="text"
            name="alert"
            placeholder="Alerta"
            value={formState.alert}
            onChange={handleChange}
          />
        </label>
      </div>
      <div className="form-group">
        <label>Tag:
          <select name="tag" value={formState.tag} onChange={handleChange}>
          <option value="LEAD">Lead</option>
          <option value="PROSPECT">Prospecto</option>
          <option value="CUSTOMER">Cliente</option>
          </select>
        </label>
      </div>
      <div className="form-buttons">
        <button type="submit">Salvar</button>
        <button type="button" onClick={handleCancel}>Cancelar</button>
      </div>
      <div className="empty-form">
        &nbsp;
      </div>
    </form>
  );
};

export default ContactForm;
