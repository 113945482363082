import { useEffect, useState } from "react";
import { w3cwebsocket as W3CWebSocket } from "websocket";
import { chatStore } from "../stores/chat-store";

export const useChat = () => {
  const [client, setClient] = useState(null);

  useEffect(() => {
    if (client) {
      client.close();
    }

    const newClient = new W3CWebSocket(`${process.env.REACT_APP_WS_URL}/ws/chat/${chatStore.phoneNumber}`);
    console.log(chatStore.phoneNumber);

    setClient(newClient);

    return () => {
      if (newClient) {
        newClient.close();
      }
    };
  }, [chatStore.phoneNumber]);



  const sendClientMessage = () => {
    if (client) {

      client.onopen = () => {
        console.log("Conexão websocket CUSTOMER estabelecida.");
      };

      client.onmessage = (message) => {
        const newMessage = JSON.parse(message.data);
        if(newMessage && newMessage.message.sender !== "customer"){
          console.log(newMessage.message);
        const msg = {
          "sender": newMessage.message.targetNumber,
          "text": newMessage.message.content,
          "role": newMessage.message.sender,
          "mediaType": newMessage.message.mediaType,
          "mediaUrl":  newMessage.message.mediaUrl
        };
      
        if (msg.text !== undefined) {
          chatStore.setViewMessages([...chatStore.viewMessages, msg]);
        }
      }
      };

      client.onclose = (event) => {
        console.log("Conexão websocket CUSTOMER fechada");
      };

      client.onerror = (error) => {
        console.error("Erro do websocket:");
      };

    } else {
    }


    // return () => {
    //   if (client && client.readyState === W3CWebSocket.OPEN) {
    //     client.close();
    //   }
    // };
  }
  useEffect(()=>{
    sendClientMessage();
  },[])

  return {
    sendClientMessage,
  };
};