// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.right-sidebar {
    background: #e9ecef;
    bottom: 0;
    right: 0;
    transition: all 0.3s;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.sidebar-content {
    height: calc(100% - 40px);
    overflow-y: auto;
    width: 100%;
}

.toggle-right-sidebar {
    width: 30px;
    height: 30px;
    background: #007bff;
    border: none;
    color: white;
    cursor: pointer;
    transition: transform 0.3s;
    margin-top: 5px;
}

.right-sidebar.expanded {
    width: 20%;
    height: 100%;
}

.right-sidebar.minimized {
    width: 20%;
    height: 40px;
}

.right-sidebar.minimized .sidebar-content {
    display: none;
}

.right-sidebar.minimized .toggle-right-sidebar {
    transform: rotate(0deg);
}

.right-sidebar.expanded .sidebar-content {
    display: block;
}

.right-sidebar.expanded .toggle-right-sidebar {
    transform: rotate(180deg);
}
`, "",{"version":3,"sources":["webpack://./src/components/RightSidebar/RightSidebar.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;IACnB,SAAS;IACT,QAAQ;IACR,oBAAoB;IACpB,gBAAgB;IAChB,aAAa;IACb,sBAAsB;IACtB,mBAAmB;AACvB;;AAEA;IACI,yBAAyB;IACzB,gBAAgB;IAChB,WAAW;AACf;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,mBAAmB;IACnB,YAAY;IACZ,YAAY;IACZ,eAAe;IACf,0BAA0B;IAC1B,eAAe;AACnB;;AAEA;IACI,UAAU;IACV,YAAY;AAChB;;AAEA;IACI,UAAU;IACV,YAAY;AAChB;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,uBAAuB;AAC3B;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI,yBAAyB;AAC7B","sourcesContent":[".right-sidebar {\n    background: #e9ecef;\n    bottom: 0;\n    right: 0;\n    transition: all 0.3s;\n    overflow: hidden;\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n}\n\n.sidebar-content {\n    height: calc(100% - 40px);\n    overflow-y: auto;\n    width: 100%;\n}\n\n.toggle-right-sidebar {\n    width: 30px;\n    height: 30px;\n    background: #007bff;\n    border: none;\n    color: white;\n    cursor: pointer;\n    transition: transform 0.3s;\n    margin-top: 5px;\n}\n\n.right-sidebar.expanded {\n    width: 20%;\n    height: 100%;\n}\n\n.right-sidebar.minimized {\n    width: 20%;\n    height: 40px;\n}\n\n.right-sidebar.minimized .sidebar-content {\n    display: none;\n}\n\n.right-sidebar.minimized .toggle-right-sidebar {\n    transform: rotate(0deg);\n}\n\n.right-sidebar.expanded .sidebar-content {\n    display: block;\n}\n\n.right-sidebar.expanded .toggle-right-sidebar {\n    transform: rotate(180deg);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
