import { TextField, MenuItem } from '@mui/material';
import styles from './styles.module.scss';

export const CustomSelect = ({ id, label, name, autoComplete, value, onChange, error, options }) => {
    return (
        <TextField
            className={styles.customSelect}
            select
            variant="outlined"
            fullWidth
            error={error}
            id={id}
            label={label}
            name={name}
            autoComplete={autoComplete}
            value={value}
            onChange={onChange}
        >
            {options.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                    {option.label}
                </MenuItem>
            ))}
        </TextField>
    );
}
